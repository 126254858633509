export const STATES = [
    "PENDIENTE",
    "PENDIENTE (REVISIÓN MOSCUZZA)",
    "RECHAZADO",
    "AUTORIZADO"
];

export const ifState = (state, tDocCodFor) => {
    if(state== "PENDIENTE" && (tDocCodFor == "NPI" || tDocCodFor == "REQ" || tDocCodFor == "NPIP")){
        return ["AUTORIZADO", "RECHAZADO"];
    }else if(state== "RECHAZADO" && (tDocCodFor == "NPI" || tDocCodFor == "REQ" || tDocCodFor == "NPIP")){
        return [];
    }else if(state== "AUTORIZADO" && (tDocCodFor == "NPI" || tDocCodFor == "REQ" || tDocCodFor == "NPIP")){
        return [];
    }else if(state== "PENDIENTE" && (tDocCodFor == "OC" || tDocCodFor == "OCP")){
        return ["PENDIENTE (REVISIÓN MOSCUZZA)", "AUTORIZADO", "RECHAZADO"];
    }else if(state== "PENDIENTE (REVISIÓN MOSCUZZA)" && (tDocCodFor == "OC" || tDocCodFor == "OCP")){
        return ["PENDIENTE", "AUTORIZADO", "RECHAZADO"];
    }else if(state== "RECHAZADO" && (tDocCodFor == "OC" || tDocCodFor == "OCP")){
        return [];
    }else if(state== "AUTORIZADO" && (tDocCodFor == "OC" || tDocCodFor == "OCP")){
        return [];
    }

    return [];
}

export const ifStateBack = (state, tDocCodFor) => {
    if((state == "PENDIENTE" || state == "AUTORIZADO" || state == "RECHAZADO")  && (tDocCodFor == "NPI" || tDocCodFor == "REQ" || tDocCodFor == "NPIP")){
        return [];
    }else if((state == "PENDIENTE" || state == "PENDIENTE (REVISIÓN MOSCUZZA)" || state == "AUTORIZADO" || state == "RECHAZADO") && (tDocCodFor == "OC" || tDocCodFor == "OCP")){
        return [];
    }

    return [];
}