//Componentes generales
import React from "react";

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLocation
} from "@fortawesome/free-solid-svg-icons";

//Estilos
import './WarehouseSectorList.css'

/*
    Componente encargado de la vista de los sectores de depósitos disponibles.
*/
function WarehouseSectorList({ sectores = [], deposito, depositoSelected }) {

    return (
        <div className={`warehouseSectorListDiv ${!(deposito === depositoSelected) ? 'inactive' : ''}`}>
            { (sectores.length > 0) ? sectores.map((sector, index) => (
                <h4 className="sectorLabel" key={index}>
                    <FontAwesomeIcon icon={faLocation} />{sector.sector} ({sector.descrp})
                </h4>
            )) : <div className="sectorDiv">
                <h4>Este depósito no contiene sectores.</h4>
            </div>}
        </div>
    );
}

export default WarehouseSectorList;

