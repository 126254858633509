import React, { useState, useEffect } from 'react';
import './GrupoItem.css';
// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserAlt,
  faPenToSquare,
  faTrash,
  faEye,
  faBell,
  faUserGroup,
  faBracketCurlyRight,
} from '@fortawesome/free-solid-svg-icons';

function GrupoItem({ 
    data = { name: 'Everyone', cantUser: '10' }, 
    selectMod = () => console.log("Estoy seleccionando"), 
    selectNot = () => console.log("Estoy seleccionando"), 
    selectDelete = () => console.log("Estoy eliminando"),
    selectInfo = () => console.log("Estoy seleccionando")
  }) {

  
  return (
      <div className="container-item-group">
          <div className="list-item-icon">
            <FontAwesomeIcon icon={faUserGroup} />
          </div>

          <div className='data-item-group'>
            <div className="data-item-group-column">
              <div className="label-data-group">Nombre</div>
              <div className="info-data-group">{data.name}</div>
            </div>
            <div className="data-item-group-column">
              <div className="label-data-group">Cantidad Usuarios</div>
              <div className="info-data-group">{data.cantUser}</div>
            </div>
          </div>

          <div className="acciones-item-group">
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="icon-group"
              onClick={selectMod}
            />
            <FontAwesomeIcon
              icon={faTrash}
              className="icon-group"
              onClick={selectDelete}
            />
            <FontAwesomeIcon
              icon={faEye}
              className="icon-group"
              onClick={selectInfo}
            />
            <FontAwesomeIcon
              icon={faBell}
              className="icon-group"
              onClick={selectNot}
            />
          </div>
      </div>
    
  );
}

export default GrupoItem;
