//Componentes generales
import React from "react";

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPerson
} from "@fortawesome/free-solid-svg-icons";

    const RenderRow = ({ index, style, responsibles = [], filter }) => {
            if (filter === '') {
                return (
                    <div style={style} className='container-a-list'>
                        <li>
                            <div className='container-a-list-item'>
                                <div className="container-a-list-item-horizontal">
                                    <div className='list-item-icon-a'><FontAwesomeIcon icon={faPerson} /></div>
                                    <div className="label-title-applicants">ID: <div className="label-responsibles">{responsibles[index].idSql}</div></div>
                                    <div className="label-title-responsibles">Nombre: <div className="label-responsibles">{responsibles[index].name}</div> </div>
                                </div>
                            </div>
                        </li>
                    </div>);
            }else{
                const responsibles_filters = responsibles.filter(a => a.name.toLowerCase().includes(filter.toLowerCase()) || a.idSql.toString().includes(filter));
                return (
                    <div style={style} className='container-a-list'>
                        <li>
                            <div className='container-a-list-item'>
                                <div className="container-a-list-item-horizontal">
                                    <div className='list-item-icon-a'><FontAwesomeIcon icon={faPerson} /></div>
                                    <div className="label-title-responsibles">ID: <div className="label-responsibles">{responsibles_filters[index].idSql}</div></div>
                                    <div className="label-title-responsibles">Nombre: <div className="label-responsibles">{responsibles_filters[index].name}</div> </div>
                                </div>
                            </div>
                        </li>
                    </div>);
            }
};

export default RenderRow