//Componentes generales
import { useContext, useEffect, useState } from "react";
import StyledNavbar_top from "../navbar/StyledNavbar_top";
import StyledNavbar_bottom from "../navbar/StyledNavbar_bottom";
import Error404 from "../error404/Error404";

//Contexto para validacion de inicio de sesion
import authContext from "../../context/authentication/authContex";

/* 
  Componente encargado de hacer permanentes las barras de navegación top y bottom, lo unico que se actualiza es lo del medio.
      Donde children es el componente que puede cambiar.
            section es el titulo de la sección es que esta posicionado.
*/
const Home = ({ children, section }) => {
  //Context auth
  const aContext = useContext(authContext);
  const { usuario, verificarLicencia } = aContext;

  const [licencia, setLicencia] = useState(true)

  /* 
    Disparador que se ejecuta al cargar el componente.
  */

  useEffect(() => {
    async function fp() {
      try {
        await verificarLicencia();
      } catch (error) {
        setLicencia(false)
        console.log("ERROR EN INICIAR SESION");
      }
    }
    fp();
  }, [usuario]);

  if (usuario && licencia) {
    return (
      <div className="sharedLayout">
        <StyledNavbar_top section={section} />
        <div>{children}</div>
        <StyledNavbar_bottom usuario={usuario} />
      </div>
    );
  } else {
    setTimeout(()=>{
      return (
        <div><Error404/></div>
      )
    },3000)
  }
};
export default Home;
