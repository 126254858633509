
//Types
import { 
    BUSCAR_TDOCS,
    MODIFICAR_FILTROS,
    CARGANDO_CONTROL,
    ACTUALIZAR_TDOC,
    AGREGAR_TDOC,
    APLICAR_FILTROS,
    INICIALIZAR_FILTROS,
    MODIFICAR_FILTROS_ESTADOS,
    MODIFICAR_FILTROS_RANGO,
    INICIAR_FILTROS
    
} from "./controlActions";

const controlReducer = (state, action) => {
    switch(action.type){
        
        case INICIAR_FILTROS: {
            return {
                ...state,
                filtrosEstado: action.payload.filtrosEstado,
                listTDoc: action.payload.tDocs,
                filtrosEstadoPredeterminado: action.payload.filtrosEstado,
                cargando: false
            }
        }

        case MODIFICAR_FILTROS_RANGO: {
            return {
                ...state,
                filtrosRango: action.payload.filtrosRango,
                listTDoc: action.payload.listaRequerimientos,
                cargando: false
            }
        }

        case MODIFICAR_FILTROS_ESTADOS: {
            return {
                ...state,
                filtrosEstado: action.payload,
                cargando: false
            }
        }

        case AGREGAR_TDOC: { 
            return {
                ...state, 
                listTDoc: (state.listTDoc !== null) ? [action.payload , ...state.listTDoc ] : []
            }
        }
        

        case ACTUALIZAR_TDOC: {
            return {
                ...state,
                listTDoc: state.listTDoc.map((t) => t._id === action.payload._id ? action.payload : t)
            }
        }
        //xx

        default:
            return state;
    }
}

export default controlReducer;