//Componentes generales
import React, { useState, useEffect, useContext } from "react";
import SearchInput from "../../components/searchInput/SearchInput";
import AddItm from "../addItm/AddItm";
import windowSizeContext from "../../context/windowSize/windowSizeContext";

// Estilos
import "./GrupoList.css";

//Components
import GrupoItem from "../GrupoItem/GrupoItem";
import FormGroup from "../FormGroup/FormGroup";

import clienteAxios from "../../config/axios";
import { getUsuariosByGroup } from "../../api/group";
import FormNotificacion from "../FormNotificacion/FormNotificacion";
import GrupoInfo from "../GrupoInfo/GrupoInfo";

function GrupoList({
  grupos = [],
  agregarGroup = () => console.log("Agregar un grupo"),
  modificarGroup = () => console.log("Modificar un grupo"),
  eliminarGroup = () => console.log("Eliminar un grupo"),
  enviarNotificacion = () => console.log("Estoy enviando notificacion"),
}) {
  const [verFormAddGroup, setVerFormAddGroup] = useState(false);
  const [verFormNotificacion, setVerFormNotificacion] = useState(false);
  const [verInfoGroup, setVerInfoGroup] = useState(false);
  const [groupSelect, setGroupSelect] = useState();
  const [listaUsuarios, setListaUsuarios] = useState([]);
  //state del filtro por busqueda.
  const [filter, setFilter] = useState("");

  // Calcula el valor del height según el tamaño de la pantalla y el tamaño del navbar
  const wSContext = useContext(windowSizeContext);
  let { height } = wSContext;

  const handlerSeleccionarGroup = async (group, typeSelect = "") => {
    try {
      const resp = await getUsuariosByGroup(group._id);
      const users = resp.data.data.map((d) => {
        return { value: d.user._id, label: d.user.userName };
      });
      /*  console.log(users); */

      setGroupSelect({ group, members: users, typeSelect });
    } catch (err) {
      console.log(err);
    }
  };

  const handlerCancelarForm = () => {
    setVerFormNotificacion(false);
    setVerFormAddGroup(false);
    setVerInfoGroup(false);
    setGroupSelect(undefined);
  };

  /* 
        Función que setea el filtro de busqueda.
            Donde data es lo ingresado por el usuario.
    */
  const handdlerSearchInput = (data) => {
    setFilter(data);
  };

  useEffect(() => {
    if (groupSelect !== undefined) {
      switch (groupSelect.typeSelect) {
        case "notificacion":
          return setVerFormNotificacion(true);
        case "modificacion":
          return setVerFormAddGroup(true);
        case "info":
          return setVerInfoGroup(true);
        default:
          return setVerFormAddGroup(true);
      }
    }
  }, [groupSelect]);

  useEffect(() => {
    return async () => {
      if (!verFormAddGroup && !verInfoGroup) {
        try {
          const resp = await clienteAxios.get(`/user`);
          setListaUsuarios(
            resp.data.data.map((u) => ({ label: u.userName, value: u._id }))
          );
        } catch (err) {
          setListaUsuarios([]);
        }
      }
    };
  }, [verFormAddGroup, verInfoGroup]);

  return (
    <div>
      <AddItm titulo="grupo" anadiritm={() => setVerFormAddGroup(true)} />

      <div style={{marginLeft:"-300px"}}><SearchInput defaultValue={filter} buscar={handdlerSearchInput} /></div>
      <div className="container-group-list" style={{ height: height - 210 }}>
        {grupos !== null &&
          grupos.map((g) => {
            if (
              filter === "" ||
              g.name.toLowerCase().includes(filter.toLowerCase()) ||
              g.name.toLowerCase().includes(filter.toLowerCase())
            ) {
              return (
                <GrupoItem
                  key={g._id}
                  data={g}
                  selectMod={() => handlerSeleccionarGroup(g, "modificacion")}
                  selectNot={() => handlerSeleccionarGroup(g, "notificacion")}
                  selectDelete={() => eliminarGroup(g._id)}
                  selectInfo={() => handlerSeleccionarGroup(g, "info")}
                />
              );
            }
          })}
      </div>
      {verFormAddGroup && (
        <div className="modalUser">
          <FormGroup
            titulo={
              groupSelect === undefined ? "Agregar Grupo" : "Modificar Grupo"
            }
            minimizar={handlerCancelarForm}
            listaUsuarios={listaUsuarios}
            generar={groupSelect === undefined ? agregarGroup : modificarGroup}
            data={groupSelect}
          />
        </div>
      )}
      {verFormNotificacion && (
        <div className="modalUser">
          <FormNotificacion
            titulo={"Notificacion"}
            minimizar={handlerCancelarForm}
            listaUsuarios={listaUsuarios}
            generar={enviarNotificacion}
            data={groupSelect}
          />
        </div>
      )}

      {verInfoGroup && (
        <div className="modalUser">
          <GrupoInfo data={groupSelect} minimizar={handlerCancelarForm} />
        </div>
      )}
    </div>
  );
}

export default GrupoList;
