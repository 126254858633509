//Componentes generales
import React from "react";

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLocation
} from "@fortawesome/free-solid-svg-icons";

    const RenderRow = ({ index, style, reqsSectors = [], filter }) => {
            if (filter === '') {
                return (
                    <div style={style} className='container-rS-list'>
                        <li>
                            <div className='container-rS-list-item'>
                                <div className="container-rS-list-item-horizontal">
                                    <div className='list-item-icon-rS'><FontAwesomeIcon icon={faLocation} /></div>
                                    <div className="label-title-reqs-sectors">Sector de la Empresa: <div className="label-reqs-sectors">{reqsSectors[index].codSem}</div></div>
                                    <div className="label-title-reqs-sectors">Descripción: <div className="label-reqs-sectors">{reqsSectors[index].descrp}</div> </div>
                                    <div className="label-title-reqs-sectors">Dimensiones: <div className="label-reqs-sectors">{reqsSectors[index].codDim}</div> </div>
                                    <div className="label-title-reqs-sectors">Subcuenta: <div className="label-reqs-sectors">{reqsSectors[index].subCue}</div> </div>
                                    <div className="label-title-reqs-sectors">Responsable: <div className="label-reqs-sectors">{reqsSectors[index].responsable}</div> </div>
                                </div>
                            </div>
                        </li>
                    </div>);
            }else{
                const reqs_sectors_filters = reqsSectors.filter(rS => rS.codSem.toLowerCase().includes(filter.toLowerCase()) || rS.descrp.toLowerCase().includes(filter.toLowerCase()));
                return (
                    <div style={style} className='container-rS-list'>
                        <li>
                            <div className='container-rS-list-item'>
                                <div className="container-rS-list-item-horizontal">
                                    <div className='list-item-icon-rS'><FontAwesomeIcon icon={faLocation} /></div>
                                    <div className="label-title-reqs-sectors">Sector de la Empresa: <div className="label-reqs-sectors">{reqs_sectors_filters[index].codSem}</div></div>
                                    <div className="label-title-reqs-sectors">Descripción: <div className="label-reqs-sectors">{reqs_sectors_filters[index].descrp}</div> </div>
                                    <div className="label-title-reqs-sectors">Dimensiones: <div className="label-reqs-sectors">{reqs_sectors_filters[index].codDim}</div> </div>
                                    <div className="label-title-reqs-sectors">Subcuenta: <div className="label-reqs-sectors">{reqs_sectors_filters[index].subCue}</div></div>
                                    <div className="label-title-reqs-sectors">Responsable: <div className="label-reqs-sectors">{reqs_sectors_filters[index].responsable}</div></div>
                                </div>
                            </div>
                        </li>
                    </div>);
            }
};

export default RenderRow