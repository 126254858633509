//Componentes generales
import React, { useContext, useEffect } from "react";
import ApplicantList from "../../components/ApplicantList/ApplicantList";

//Context
import applicantsContext from "../../context/solicitantes/applicantsContext";
import authContext from "../../context/authentication/authContex";

// Alertas
import Swal from "sweetalert2";

//Estilos
import "./Applicant.css";

/* 
    Vista donde se gestiona toda la lógica de listado de solicitantes.
        Donde props no se encuentra en uso.
*/
function Applicant(props) {
  //Context Auth
  const aContext = useContext(authContext);
  const { usuario } = aContext;

  //Context solicitantes
  const apContext = useContext(applicantsContext);
  const { applicants, actualizarApplicants, actualizarApplicantsDB } =
    apContext;

  /* 
        Disparador que se ejecuta ante la renderización del componente. El mismo se encarga de actualizar los solicitantes disponibles del context.
    */
  useEffect(() => {
    const fn = async () => {
      await actualizarApplicants();
    };
    fn();
  }, []);

  /* 
        Manejador que se encarga de la actualización de solicitantes que sobreescribe los solicitantes actuales por los recuperados de Softland.
    */
  const handlerActualizarApplicants = async () => {
    Swal.fire({
      title: "Desea actualizar la lista de solicitantes?",
      text: "Se traeran los datos de Softland para sobreescribir la base de datos de la aplicación.",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "No, cancelar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await actualizarApplicantsDB(usuario._id, usuario.userName)
          .then((response) => {
            if (!response.status) {
              throw new Error(response.msg);
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Actualizado!",
          "La base de datos ha sido actualizada correctamente.",
          "success"
        );
      }
    });
  };

  return (
    <div className="container-list">
      <div className="actualiza-applicants-container">
        <button
          className="actualizar-applicants-btn"
          onClick={handlerActualizarApplicants}
        >
          Actualizar Solicitantes
        </button>
      </div>
      <ApplicantList applicants={applicants} />
    </div>
  );
}

export default Applicant;
