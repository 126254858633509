//Componentes generales
import React, { useReducer, useContext, useEffect } from "react";
import { addGroup, deleteGroup, getAllGroup, updateGroup } from "../../api/group";

import gruposReducer from "./gruposReducer";
import gruposContext from "./gruposContext";

//Socket
import { SocketContext } from "../socket/socket";

//Types
import { 
    ACTUALIZAR_LIST_GROUP,
    MODIFICAR_GROUP,
    AGREGAR_GROUP,
    ELIMINAR_GROUP,
  } from "./gruposActions";
import { sendNotificationPushByUserIdGroup } from "../../api/user";



/* 
    State encargado de la gestión de usuarios.
*/
const GruposState = props => {

    const initialState = {
       gruposList: null
    };

    const server = useContext(SocketContext);
    
    const [state, dispatch] = useReducer(gruposReducer, initialState);
    
    const actualizarGroupList = async () => {
        try{
            const resp = await getAllGroup();
            const grupos = resp.data.data;

            dispatch({
                type: ACTUALIZAR_LIST_GROUP,
                payload: grupos
            });
            
            return grupos;
        }catch(err){
            console.log(err)
        }

    };

    const agregarGroup = async (grupo, usuariosId) => {

        try{
            const{name, cantUser} = grupo;
            const resp = await addGroup({name, cantUser}, usuariosId);
            const grupos = resp.data.data;

            return grupos;
        }catch(err){
            console.log(err);
        }
        
    };

    const modificarGroup = async (id, updates) => {
        try{
            console.log(id);
            console.log(updates);
            const resp = await updateGroup(id, updates);
        }catch(err){
            console.log(err)
        }
    }

    const eliminarGroup = async (id) => {
        try{
            console.log(id);
            const resp = await deleteGroup(id);
        }catch(err){
            console.log(err)
        }
    }

    const enviarNotificacionPush = async (groupId, title, message) => {
        try{
            const resp = await sendNotificationPushByUserIdGroup(groupId, title, message);
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        if(server){
            //Grupo Nuevo
            server.on('addGroup', (resp) => {
                dispatch({
                    type: AGREGAR_GROUP,
                    payload: resp.data
                })
            });
            server.on('modGroup', (resp) => {
                console.log(resp);
                dispatch({
                    type: MODIFICAR_GROUP,
                    payload: resp.data
                })
            });

            server.on('deleteGroup', (resp) => {
                console.log(resp);
                dispatch({
                    type: ELIMINAR_GROUP,
                    payload: resp.data
                })
            });
    
            return () => server.off(['addGroup', 'modGroup', 'deleteGroup']);
        }
    },[server])

    return(
        <gruposContext.Provider
            value={{
                gruposList: state.gruposList,
                actualizarGroupList,
                agregarGroup,
                modificarGroup,
                eliminarGroup,
                enviarNotificacionPush
            }}
        >
            {props.children}
        </gruposContext.Provider>
    )
    
}



export default GruposState;

