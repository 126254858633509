import { 
    ACTUALIZAR_SOLICITANTES,
    ACTUALIZAR_SOLICITANTES_LIST,
    ACTUALIZAR_SOLICITANTES_DB,
    CARGANDO_SOLICITANTES,
    ERROR_SOLICITANTES,
    LIMPIAR_ERROR,
} from "./applicantsAction";

const applicantsReducer = (state, action) => {
    switch(action.type){

        case ACTUALIZAR_SOLICITANTES:
        case ACTUALIZAR_SOLICITANTES_DB: {
            return {
                ...state,
                applicants: action.payload,
                cargando: false,
                error: ""
            }
        }

        case ACTUALIZAR_SOLICITANTES_LIST:
            {
            return {
                ...state,
                error: "",
                applicantsList: action.payload
            }
        }

        case CARGANDO_SOLICITANTES: {
            return {
                ...state,
                cargando: true,
                error: ""
            }
        }

        case ERROR_SOLICITANTES: {
            return {
                ...state,
                cargando: false,
                error: action.payload
            }
        }

        case LIMPIAR_ERROR: {
            return {
                ...state,
                error: ""
            }
        }

    }
}

export default applicantsReducer;