//Componentes generales
import React, { useContext, useEffect } from "react";
import ResponsibleList from "../../components/ResponsibleList/ResponsibleList";

//Context
import responsiblesContext from "../../context/responsables/responsiblesContext";
import authContext from "../../context/authentication/authContex";

// Alertas
import Swal from "sweetalert2";

//Estilos
import "./Responsible.css";

/* 
    Vista donde se gestiona toda la lógica de listado de responsables.
        Donde props no se encuentra en uso.
*/
function Responsible(props) {
  //Context Auth
  const aContext = useContext(authContext);
  const { usuario } = aContext;

  //Context responsables
  const rContext = useContext(responsiblesContext);
  const { responsibles, actualizarResponsibles, actualizarResponsiblesDB } =
    rContext;

  /* 
        Disparador que se ejecuta ante la renderización del componente. El mismo se encarga de actualizar los responsables disponibles del context.
    */
  useEffect(() => {
    const fn = async () => {
      await actualizarResponsibles();
    };
    fn();
  }, []);

  /* 
        Manejador que se encarga de la actualización de responsables que sobreescribe los responsables actuales por los recuperados de Softland.
    */
  const handlerActualizarResponsibles = async () => {
    Swal.fire({
      title: "Desea actualizar la lista de responsables?",
      text: "Se traeran los datos de Softland para sobreescribir la base de datos de la aplicación.",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "No, cancelar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await actualizarResponsiblesDB(usuario._id, usuario.userName)
          .then((response) => {
            if (!response.status) {
              throw new Error(response.msg);
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Actualizado!",
          "La base de datos ha sido actualizada correctamente.",
          "success"
        );
      }
    });
  };

  return (
    <div className="container-list">
      <div className="actualiza-responsibles-container">
        <button
          className="actualizar-responsibles-btn"
          onClick={handlerActualizarResponsibles}
        >
          Actualizar Responsables
        </button>
      </div>
      <ResponsibleList responsibles={responsibles} />
    </div>
  );
}

export default Responsible;