//Componentes generales
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Routes_gral from "../../utils/Routes_gral";

//Estilos
import "./Login.css";

//Imagenes
import logoXertux from "../../images/LOGO-PNG-SIN-FONDO.png";
import logoG4 from "../../images/LOGO-BLANCO.png";

//Contexto
import authContext from "../../context/authentication/authContex";

// Alertas
import Swal from "sweetalert2";

/* 
    Vista donde se gestiona toda la lógica de inicio de sesión en la aplicación.
        Donde props no se encuentra en uso.
*/
function Login(props) {
  //Context de Autenticacion
  const aContext = useContext(authContext);
  const {
    usuario,
    mensaje,
    token,
    autenticado,
    iniciarSesion,
    usuarioAutenticado,
    verificarLicencia,
  } = aContext;

  //Inicializo navegacion
  const navigate = useNavigate();

  //State
  const [usuarioState, setUsuarioState] = useState({
    userName: "",
    password: "",
  });

  /*
        Función que muestra una alerta popIt del mensaje seteado en el contexto. 
    */
  const alerta = (mensaje) => {
    Swal.fire({
      text: mensaje,
      icon: "warning",
      confirmButtonText: "OK",
    });
  };

  /* 
        Disparador que se ejecuta ante cambios en la variable mensaje proveniente del context. El mismo llama a la función de alerta.
    */
  useEffect(() => {
    if (mensaje != null) alerta(mensaje);
  }, [mensaje]);

  /* 
        Disparador que se ejecuta ante cambios en la variable usuario proveniente del context. Si el usuario esta autenticado, y la licencia esta vigente, redirige a vista Home.
    */
  useEffect(() => {
    const fn = async () => {
      try {
        const diasLicencia = await verificarLicencia();

        // Es espera a que el usuario tenga datos
        if (autenticado === true && usuario !== null && diasLicencia >= 0) {
          navigate(Routes_gral.home);
        }
      } catch (error) {
        console.error("Error al verificar licencia:", error);
      }
    };

    fn();
  }, [usuario]);

  /* 
        Función que controla el evento de Inicio de Sesión (Botón). En el mismo se verifica la licencia e intenta iniciar sesión.
    */
  const onSubmit = async () => {

    try {
      await verificarLicencia();

    } catch (error) {
      console.log("ERROR EN INICIAR SESION");
      alerta("LICENCIA DE USO VENCIDA. Comuníquese con el Administrador.");
    }

    try {

      const resp = await iniciarSesion(usuarioState);
      if (!resp) throw Error;
  
      await usuarioAutenticado();

    } catch (error) {
      console.log("Error en iniciar sesión:", error);
      alerta("ERROR AL INICIAR SESIÓN. Verifique sus credenciales o contacte al administrador.");
    }
  };

  /* 
        Manejador que setea el usuario por el ingresado.
            Donde userText es el texto ingresado.
    */
  const handlerInputUser = (userText) =>
    setUsuarioState({ ...usuarioState, userName: userText.target.value });

  /* 
        Manejador que setea la contraseña por la ingresado.
            Donde passText es el texto ingresado.
    */
  const handlerInputPass = (passText) =>
    setUsuarioState({ ...usuarioState, password: passText.target.value });

  return (
    <div className="container-login">
      <div className="card-login">
        <div className="containerImg">
          <img className="imgMain" src={logoXertux} alt="Logo-Principal" />
        </div>
        <div className="containerForm">
          <div className="containerInput">
            <input
              className="textInput"
              id="username"
              placeholder="Username"
              type="text"
              name="username"
              onChange={handlerInputUser}
            />
          </div>
          <div className="containerInput">
            <input
              className="textInput"
              id="password"
              placeholder="Password"
              type="password"
              name="user_password"
              onChange={handlerInputPass}
              onKeyDown={(keyName) =>
                keyName.key === "Enter" ? onSubmit() : null
              }
            />
          </div>
          <div className="btnSubmitContainerStyle">
            <button id="myBtn" className="btnSubmitStyle" onClick={onSubmit}>
              Ingresar
            </button>
          </div>
        </div>
      </div>
      <div className="containerG4">
        <div className="containerG4Text">UN PRODUCTO DE </div>
        <img className="containerG4Img" src={logoG4} alt="Logo-Bottom" />
      </div>
    </div>
  );
}

export default Login;
