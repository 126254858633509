export const CARGAR_REQ = "CARGAR_REQ";
export const MODIFICAR_REQ = "MODIFICAR_REQ";
export const SELECCIONAR_PRODUCTO = "SELECCIONAR_PRODUCTO"; 
export const CARGAR_ITEM = "CARGAR_ITEM";
export const ELIMINAR_ITEM = "ELIMINAR_ITEM";
export const MODIFICAR_ITEM = "MODIFICAR_ITEM";
export const SELECCIONAR_ITEM = "SELECCIONAR_ITEM";
export const CARGAR_PRODUCTOS = "CARGAR_PRODUCTOS";
export const GUARDAR_REQUERIMIENTO = "GUARDAR_REQUERIMIENTO";
export const CARGANDO_ADD = "CARGANDO_ADD";
export const ERROR_ADD = "ERROR_ADD";
export const SELECCIONAR_FILE = "SELECCIONAR_FILE";
export const ELIMINAR_FILE = "ELIMINAR_FILE";
export const ERROR = "ERROR"; 
export const LIMPIAR_ERROR = "LIMPIAR_ERROR";