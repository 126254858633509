const ENTORNO = 'produccionHTTPS';

const local = {
    url: '//localhost',
    port: '3000',
    realTimeServerUrl: '//localhost',
    realTimeServerPort: '3000'
}

const desarrollo = {
    url: 'http://54.218.129.37',
    port: '3000',
    realTimeServerUrl: 'http://54.218.129.37',
    realTimeServerPort: '3000'
}

const personal = {
    url: 'http://54.218.129.37',
    port: '3000',
    realTimeServerUrl: 'http://54.218.129.37',
    realTimeServerPort: '4000'
}

const produccion = {
    url: 'http://54.218.129.37',
    port: '3000',
    realTimeServerUrl: 'http://54.218.129.37',
    realTimeServerPort: '3000'
}

const produccionHTTPS = {
    url: 'https://xertux.moscuzza.com',
    realTimeServerUrl: 'https://xertux.moscuzza.com'
}

const test = {
    url: 'http://54.218.129.37',
    port: '3000',
    realTimeServerUrl: 'http://54.218.129.37',
    realTimeServerPort: '3000'
}



export function getRealTimeServerURL() {

    switch (ENTORNO) {
        case 'local':
            return `${local.realTimeServerUrl}:${local.realTimeServerPort}`;
            break;
        case 'desarrollo':
            return `${desarrollo.realTimeServerUrl}:${desarrollo.realTimeServerPort}`;
            break;
        case 'personal':
            return `${personal.realTimeServerUrl}:${personal.realTimeServerPort}`;
            break;
        case 'produccion':
            return `${produccion.realTimeServerUrl}:${produccion.realTimeServerPort}`;
            break;
        case 'produccionHTTPS':
            return `${produccionHTTPS.realTimeServerUrl}`;
            break;
        case 'test':
            return `${test.realTimeServerUrl}:${test.realTimeServerPort}`;
            break;
    }
}

export function getServerURL() {

    switch (ENTORNO) {
        case 'local':
            return `${local.url}:${local.port}/api`;
            break;
        case 'desarrollo':
            return `${desarrollo.url}:${desarrollo.port}/api/`;
            break;
        case 'personal':
            return `${personal.url}:${personal.port}/`;
            break;
        case 'produccion':
            return `${produccion.url}:${produccion.port}/api/`;
            break;
        case 'produccionHTTPS':
            return `${produccionHTTPS.url}/api/`;
            break;
        case 'test':
            return `${test.url}:${test.port}/api/`;
            break;
    }
}