import clienteAxios from "../config/axios";

export async function getAbmProduct () {
    return clienteAxios.get(`/abmProduct`);
}

export async function getAbmProductByTDoc (tDocCodFor, tDocNroFor) {
    return clienteAxios.post(`/abmProduct/byTDoc`, { tDocCodFor, tDocNroFor});
}

export async function uploadAbmProduct ( userID, userName) {
    return clienteAxios.post('/abmProduct/actualizar', {userID, userName});
}