//Componentes generales
import React, { useContext, useEffect } from "react";
import ProductList from "../../components/ProductList/ProductList";

//Context
import productsContext from "../../context/productos/productsContext";
import authContext from '../../context/authentication/authContex';

// Alertas
import Swal from 'sweetalert2'

//Estilos
import './Product.css';

/* 
    Vista donde se gestiona toda la lógica de listado de productos.
        Donde props no se encuentra en uso.
*/
function Product(props) {

    //Context Auth
    const aContext = useContext(authContext);
    const { usuario } = aContext;


    //Context Add
    const pContext = useContext(productsContext);
    const { products, actualizarProducts, actualizarProductsDB } = pContext;

    /* 
        Disparador que se ejecuta ante la renderización del componente. El mismo se encarga de actualizar los productos disponibles del context.
    */
    useEffect(() => {
        const fn = async () => {
            await actualizarProducts();
        }
        fn();
    }, []);

    /* 
        Manejador que se encarga de la actualización de productos que sobreescribe los productos actuales por los recuperados de Softland.
    */
    const handlerActualizarProductos = async () =>
    { 
        Swal.fire({
            title: 'Desea actualizar la lista de productos?',
            text: "Se traeran los datos de Softland para sobreescribir la base de datos de la aplicación.",
            showCancelButton: true,
            confirmButtonText: 'Actualizar',
            cancelButtonText: 'No, cancelar',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              return await actualizarProductsDB(usuario._id, usuario.userName)
                .then(response => {
                  if (!response.status) {
                    throw new Error(response.msg)
                  }
                  return response;
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
                    Swal.fire(
                        'Actualizado!',
                        'La base de datos ha sido actualizada correctamente.',
                        'success'
                    )
            }
          })
    }


    return (
        <div>
            <div className="actualiza-productos-container">
                <button className="actualizar-productos-btn" onClick={handlerActualizarProductos}>Actualizar Productos</button>
            </div>
            <ProductList productos={products} />
        </div>
    );
}

export default Product