import { 
    ACTUALIZAR_DEPOSITOS,
    ACTUALIZAR_SECTORES,
    ACTUALIZAR_DEPOSITOS_DB,
    CARGANDO_DEPOSITOS,
    ERROR_DEPOSITOS,
    LIMPIAR_ERROR,
} from "./warehousesAction";

const warehousesReducer = (state, action) => {
    switch(action.type){

        case ACTUALIZAR_DEPOSITOS:
        case ACTUALIZAR_DEPOSITOS_DB: {
            return {
                ...state,
                warehouses: action.payload,
                cargando: true,
                error: ""
            }
        }
        case ACTUALIZAR_SECTORES: {
            return {
                ...state,
                sectors: action.payload,
                cargando:false,
                error: ""
            }
        }

        case CARGANDO_DEPOSITOS: {
            return {
                ...state,
                cargando: true,
                error: ""
            }
        }

        case ERROR_DEPOSITOS: {
            return {
                ...state,
                cargando: false,
                error: action.payload
            }
        }

        case LIMPIAR_ERROR: {
            return {
                ...state,
                error: ""
            }
        }
    }
}

export default warehousesReducer;