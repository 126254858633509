//Componentes generales
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//Estilos
import './FormGroup.css';

// Alertas
import Swal from 'sweetalert2'
import DropDownPicker from '../DropDownPicker/DropDownPicker';
import { compararArrayOptions } from '../../utils/funciones';

/*
    Componente encargado de mostrar el formulario de usuario.
        Donde data es el usuario crear/modificar.
              minimizar es la función encargada de ocultar el dialogo.
              generar es la función del context que guarda el usuario.
              titulo es la que determina que texto mostrar en encabezado del dialogo.
              verficaUser es la función del context que comprueba la licencia por usuario vigente del cliente.
*/
function FormGroup({ data = undefined, minimizar, generar = () => console.log("Generando"), titulo, listaUsuarios = [] }) {

    //State de usuario. Si no viene data por parametro significa que es nuevo por lo que se le asigna una estructura.
    const [groupComplete, setGroupComplete] = useState((data !== undefined) ? data : {group: {name: '', _id: ""}, members: []});

    //Constante que define el titulo que corresponde si vienen datos por parametros
    const tituloBoton = (data === undefined) ? 'Agregar' : 'Modificar';

    const handlerGenerar = () => {
        
        const {group, members} = groupComplete;

        const usuariosId = members.map((u) => u.value);

        if(data === undefined){
            console.log("Agrego un Grupo")
            generar(group, usuariosId);
        }else{

            let updates = {};

            if(compararArrayOptions(data.members, members) === false) updates.usuariosId = usuariosId; 
            if(group.name !== data.group.name) updates.name = group.name; 

            console.log(updates);

            if(Object.values(updates).length > 0) generar(group._id, updates);
            else console.log("No modifico");
            
            
        }
        minimizar();
    }


    return (
        <div className='container-group-form'>
            <div className='title-form-group'>
                {titulo}
            </div>
            <div className='container-form'>
                <div className='label-form-group'>Nombre: </div>
                <input value={groupComplete.group.name} className='label-form-group-data' onChange={data => setGroupComplete({ ...groupComplete, group: {...groupComplete.group, name: data.target.value} })} />
            </div>

            <div className='container-form'>
                <div className='label-form-group'>Miembros: </div>
                <DropDownPicker
                    options={listaUsuarios}
                    onChange={(value) => setGroupComplete({ ...groupComplete, members: value})}
                    value={groupComplete.members}
                />
            </div>

            <div className='count-user-picker-group'>
                Cantidad: <span style={{fontWeight: "bold", color: "black"}}>{groupComplete.members.length}</span>
            </div>

            <div className='container-btn-group'>
                <button className='btn-form-group' onClick={handlerGenerar}>{tituloBoton}</button>
                <button className='btn-cancel-form-group' onClick={minimizar}>Cancelar</button>
            </div>
        </div>
    );
}

export default FormGroup