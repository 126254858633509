//Componentes generales
import React, { useState, useMemo, useContext } from "react";
import SearchInput from "../searchInput/SearchInput";
import RenderRow from "../renderRows/RRWarehouses";
import windowSizeContext from "../../context/windowSize/windowSizeContext";

//Estilos
import './WarehouseList.css'

/*
    Componente encargado de la vista de los depósitos disponibles.
    Donde depósitos es la lista de depósitos disponibles recuperados del context.
*/
function WarehouseList({ warehousesWithSectors = [] }) {

    //state de filtro.
    const [filter, setFilter] = useState("");

    // Calcula el valor del height según el tamaño de la pantalla y el tamaño del navbar
    const wSizeContext = useContext(windowSizeContext);
    const {height} = wSizeContext;

    /*
        Manejador ante cambios en filtro.
        Donde data es el texto ingresado por el usuario.
    */
    const handdlerSearchInput = (data) => {
        setFilter(data);
    }

    return (
        <div>
            <div style={{marginLeft:"-300px"}}><SearchInput defaultValue={filter} buscar={handdlerSearchInput} /></div>
            <div className='warehousesListDiv' style={{ height: height - 200, overflowY: 'scroll' }}>
                {
                    warehousesWithSectors
                        .filter(w =>
                            w.deposi.toLowerCase().includes(filter.toLowerCase()) ||
                            w.descrp.toLowerCase().includes(filter.toLowerCase()) ||
                            w.direcc.toLowerCase().includes(filter.toLowerCase())
                        )
                        .map((w, index, style) => (
                            <RenderRow
                                key={index} 
                                index={index}
                                style={{style}}
                                warehousesWithSectors={warehousesWithSectors}
                                filter={filter}
                            />
                        ))
                }
            </div>
        </div>
    );
    
}

export default WarehouseList