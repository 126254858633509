const Routes_gral = {
    login: '/',
    home: '/Home',
    user: '/User',
    group: '/Group',
    product: '/Product',
    addReq: '/addReq',
    applicant: '/applicant',
    responsible: '/responsible',
    reqSector: '/reqSector',
    warehouse: '/warehouse'
}

export default Routes_gral