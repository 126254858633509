//Componentes generales
import React, { useReducer } from "react";
import productsReducer from "./productsReducer";
import productsContext from "./productsContext";

//Axios
import clienteAxios from "../../config/axios";

//Rutas de api
import { getAbmProduct, uploadAbmProduct } from "../../api/abmProduct.js"

//Acciones
import { 
    ACTUALIZAR_PRODUCTOS,
    ACTUALIZAR_PRODUCTOS_DB,
    CARGANDO_PROD,
    ERROR_PROD,
    LIMPIAR_ERROR
} from "./productsAction";

/* 
    State encargado de la gestión de productos.
*/
const ProductsState = props => {

    //Valores iniciales
    const initialState = {
        products: [],
        cargando: false,
        error: ""
    };

    const [state, dispatch] = useReducer(productsReducer, initialState);

    /* 
        Función recuperar los productos y a su vez actualizarlos.
    */
    const actualizarProducts = async () => {

        try{

            const resp = await getAbmProduct();

            dispatch({
                type: ACTUALIZAR_PRODUCTOS,
                payload: resp.data.data
            })

            return resp.data.data;

        }catch(error){
            console.log(error);
        }
    }

    /* 
        Función encargada de sobreescribir la base de datos de productos con la propia de Softland.
            Donde userID es el ID del usuario en sesión.
                  userName es el nombre del usuario en sesión.
    */
    const actualizarProductsDB = async (userID, userName) => {

        try{
            console.log("ENTRO AL ACTUALIZAR PRODUCTOS");
            await uploadAbmProduct(userID, userName);
            const resp = await getAbmProduct();
            console.log(resp);
            dispatch({
                type: ACTUALIZAR_PRODUCTOS_DB,
                payload: resp.data.data
            });

        return {status: true, msg: "Se actualizo con exito la base de datos."};

        }catch(error){
            console.log(error);
            dispatch({
                type: ERROR_PROD,
                payload: error.response.data.errMsg
            });

            return {status: false, msg: error.response.data.errMsg};
        }
    }

    const cargando =  () => {
        dispatch({
            type: CARGANDO_PROD
        })
    }

    const limpiarError = () => {
        dispatch({
            type: LIMPIAR_ERROR
        })
    }

    return(
        <productsContext.Provider
            value={{
                products: state.products,
                isCargando: state.cargando,
                error: state.error,
                actualizarProducts,
                actualizarProductsDB,
                cargando,
                limpiarError
            }}
        >

            {props.children}

        </productsContext.Provider>
    )
    
}

export default ProductsState;