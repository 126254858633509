import clienteAxios from "../config/axios";
import tokenAuth from "../config/token";

export async function authUser(datos) {
    return clienteAxios.post('/user/auth', datos);
}

export async function getUser(token) {
    //T0D0: Funcion para enviar el token por headers
    tokenAuth(token);
    return clienteAxios.get(`/user/auth`);
}

export async function getUserById(_id) {
    return clienteAxios.get(`/user/_id/${_id}`);
}

export async function updateTokenFMC(tokenFMC, userName) {
    return clienteAxios.post(`/user/update/tokenFMC`, {tokenFMC , userName});
}

export async function deleteTokenFMC(token) {
    return clienteAxios.delete(`/user/tokenFMC/${token}`); 
}

export async function getAllUsers() {
    return clienteAxios.get('/user');
}

export async function updateUser(user) {
    return clienteAxios.put(`/user`, { id: user._id, updates: user});
}

export async function addUser(user, byUser) {
    return clienteAxios.post(`/user`, {user: {...user, userNameA: byUser.userName, userAID: byUser._id}});
}

export async function deleteUser(user) {
    return clienteAxios.delete(`/user`,{data: {id: user._id}});
}

export async function verifyQUsers() {
    return clienteAxios.get('/user/enabled/quantity');
}

export async function getUsersInGroupV(userId) {
    return clienteAxios.get(`/user/${userId}/gruposV`);
}

export async function sendNotificationPushByUserIdGroup(groupId, title, message){
    return clienteAxios.get(`/user/${groupId}/${title}/${message}/push`);
}