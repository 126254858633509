//Componentes generales
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//Estilos
import './FormNotificacion.css';

// Alertas
import Swal from 'sweetalert2'
import DropDownPicker from '../DropDownPicker/DropDownPicker';

/*
    Componente encargado de mostrar el formulario de usuario.
        Donde data es el usuario crear/modificar.
              minimizar es la función encargada de ocultar el dialogo.
              generar es la función del context que guarda el usuario.
              titulo es la que determina que texto mostrar en encabezado del dialogo.
              verficaUser es la función del context que comprueba la licencia por usuario vigente del cliente.
*/
function FormNotificacion({ data = undefined, minimizar, generar = () => console.log("Generando"), titulo, listaUsuarios = [] }) {

    //State de usuario. Si no viene data por parametro significa que es nuevo por lo que se le asigna una estructura.
    const [groupComplete, setGroupComplete] = useState((data !== undefined) ? {...data, notificacion : {title: "", message: ""}} : {group: {name: '', _id: ""}, members: [{label: "ADMIN TEST", value: "1234"}], notificacion : {title: "", message: ""}});


    const handlerGenerar = async () => {
        console.log("Estoy enviando el siguiente mensaje: ");
        console.log(groupComplete);
        generar(groupComplete.group._id, groupComplete.notificacion.title, groupComplete.notificacion.message);
        minimizar();
    }


    return (
        <div className='container-group-form'>
            <div className='title-form-group'>
                {titulo}
            </div>
            <div className='container-form'>
                <div className='label-form-group'>Nombre: </div>
                <div className='label-form-group-data'>{groupComplete.group.name}</div>
            </div>

            <div className='container-form'>
                <div className='label-form-group'>Miembros: </div>
                <DropDownPicker
                    options={listaUsuarios}
                    onChange={(value) => setGroupComplete({ ...groupComplete, members: value})}
                    value={groupComplete.members}
                    isDisabled={true}
                />
            </div>

            <div className='container-form'>
                <div className='label-form-group'>Titulo: </div>
                <input value={groupComplete.notificacion.title} className='label-form-group-data' onChange={data => setGroupComplete({ ...groupComplete, notificacion: {...groupComplete.notificacion, title: data.target.value} })} />
            </div>

            <div className='container-form'>
                <div className='label-form-group'>Mensaje: </div>
                <textarea value={groupComplete.notificacion.message} onChange={data => setGroupComplete({ ...groupComplete, notificacion: {...groupComplete.notificacion, message: data.target.value} })} className='label-form-group-data' style={{height: "10rem", resize: 'none'}}/>
            </div>

            <div className='container-btn-group'>
                <button className='btn-form-group' onClick={handlerGenerar}>{"Enviar"}</button>
                <button className='btn-cancel-form-group' onClick={minimizar}>Cancelar</button>
            </div>
        </div>
    );
}

export default FormNotificacion