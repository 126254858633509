import clienteAxios from "../config/axios";

export async function updateListProductByTDoc(tDoc, productList) {
    return clienteAxios.put('/product/updateListByTDoc', { tDoc, productList });
}

export async function getProductsByTDocNroFor(tDocCodFor ,tDocNroFor) {
    return clienteAxios.get(`/productBytDocNroFor/${tDocCodFor}/${tDocNroFor}`);
}

