//Componentes generales
import React, { useContext, useEffect, useState } from "react";
import WarehouseList from "../../components/WarehouseList/WarehouseList";

//Context
import warehousesContext from "../../context/depositos/warehousesContext";
import authContext from '../../context/authentication/authContex';

// Alertas
import Swal from 'sweetalert2'

//Estilos
import './Warehouse.css';

/* 
    Vista donde se gestiona toda la lógica de listado de depósitos.
        Donde props no se encuentra en uso.
*/
function Warehouse(props) {

    const [loading, setLoading] = useState(true);
    const [warehousesWithSectors, setWarehousesWitSectors] = useState([])

    //Context Auth
    const aContext = useContext(authContext);
    const { usuario } = aContext;

    //Context depósitos
    const apContext = useContext(warehousesContext);
    const { warehouses, sectors, actualizarWarehouses, actualizarSectors, actualizarWarehousesDB } = apContext;

    /* 
        Disparador que se ejecuta ante la renderización del componente. El mismo se encarga de actualizar los depósitos disponibles del context.
    */
    useEffect(() => {
        const fn = async () => {
            await actualizarWarehouses();
            await actualizarSectors();
            setLoading(false);
        }
        fn();
    }, []);

    useEffect(() => {
        createWarehousesWithSectors();
    }, [loading]);

    const createWarehousesWithSectors = () => {
        let newArray = warehouses.slice();
        newArray.map((w) => {
            w.sectors = sectors.filter((s) =>
              s.deposi == w.deposi 
            );
          });
        setWarehousesWitSectors(newArray)
    }

    /* 
        Manejador que se encarga de la actualización de depósitos que sobreescribe los depósitos actuales por los recuperados de Softland.
    */
    const handlerActualizarWarehouses = async () => {
        Swal.fire({
            title: 'Desea actualizar la lista de depósitos?',
            text: "Se traeran los datos de Softland para sobreescribir la base de datos de la aplicación.",
            showCancelButton: true,
            confirmButtonText: 'Actualizar',
            cancelButtonText: 'No, cancelar',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await actualizarWarehousesDB(usuario._id, usuario.userName)
                    .then(response => {
                        if (!response.status) {
                            throw new Error(response.msg)
                        }
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Actualizado!',
                    'La base de datos ha sido actualizada correctamente.',
                    'success'
                )
            }
        })
    }

    return (
        <div className="container-list">
            <div className="actualiza-warehouses-container">
                <button className="actualizar-warehouses-btn" onClick={handlerActualizarWarehouses}>Actualizar Depósitos</button>
            </div>
            <WarehouseList warehousesWithSectors={warehousesWithSectors} />
        </div>
    );
}

export default Warehouse;
