import clienteAxios from "../config/axios";

export async function addGroup(grupo, usuariosId) {
  return clienteAxios.post(`/grupo`, {grupo, usuariosId});
}

export async function getAllGroup() {
  return clienteAxios.get(`/grupo/complete`);
}

export async function getUsuariosByGroup(groupId) {
  return clienteAxios.get(`/grupoUsuarios/complete?guGrupoID=${groupId}`);
}

export async function getGroupByUser(usuarioId) {
  return clienteAxios.get(`/grupoUsuarios/complete?guUserID=${usuarioId}`);
}

export async function updateGroup(id, updates) {
  return clienteAxios.put(`/grupo`, {id, updates});
}

export async function deleteGroup(id) {
  return clienteAxios.delete(`/grupo/${id}`);
}
