//Componentes generales
import React, { useContext, useState } from 'react';
import UserInfo from '../../components/UserInfo/UserInfo';

//Estilos
import './Navbar.css';

//Contexto para recuperar usuario que se esta utilizando
import authContext from '../../context/authentication/authContex';

//Imagenes
import logoXertux from '../../images/LOGO-PNG-SIN-FONDO.png';

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIdCardClip
} from "@fortawesome/free-solid-svg-icons";

/* 
  Componente encargado del encabezado general de la pagina.
    Donde section es el titulo de la sección (Ruta nav) en que esta posicionado el usuario.
*/
const StyledNavbar_top = ({ section }) => {

  //Context de Autenticacion   
  const aContext = useContext(authContext);
  const { usuario: user } = aContext;
  //State que determina si muestra o no el dialog de información del usuario.
  const [showUserInfo, setShowUserInfo] = useState(false);

  //Chequea si esta visible el dialog de información de usuario
  const checkEstadoVisibilidad = () => {
    showUserInfo ? setShowUserInfo(false) : setShowUserInfo(true);
  }

  return (
    <div className=''>
      <div className='container-top-xx'>
        <img src={logoXertux} className='container-left-top-img-xx' alt='Logo-Top-Nav' />
        <h2 className='label-screen-h2-xx' style={{fontWeight: 'bold'}}>{section}</h2>
        <div id='userInfo' className='navbar-item-icon-xx'><FontAwesomeIcon icon={faIdCardClip} onClick={checkEstadoVisibilidad} /></div>
      </div>
      {showUserInfo &&
        <div id='idUserInfo' className='modal-xx'>
          <UserInfo user={user} verCerrarSesion={true} mode={checkEstadoVisibilidad} />
        </div>
      }
    </div>
  );
};
export default StyledNavbar_top;