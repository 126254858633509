//Estilos
import './AddItm.css'

/* 
    Componente de boton flotante de "+" que funciona como un "nuevo".
        Donde titulo recibe un texto indicando lo que se esta creando.
        Donde anadiritm recibe el componente dialog a mostrar.
*/
function AddItm({ titulo, anadiritm }) {
    return (
        <button className="icon-btn add-btn" onClick={() => anadiritm(undefined)}>
            <div className="add-icon"></div>
            <div className="btn-txt">Nuevo {titulo}</div>
        </button >
    );
}

export default AddItm