//Componentes generales
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//Tipos de Estado
import { ifState, ifStateBack } from "../../utils/states";

//Estilos
import "./StateChange.css";

// Alertas
import Swal from "sweetalert2";
import { useEffect } from "react";
import {
  getTDocByFilter,
  updateEstautToWebService,
} from "../../api/transactionDocuments";
import moment from "moment";

/* 
    Componente de vista de dialogo de cambio de estado.
    Donde item es el requerimiento a modificar el requerimiento.
    guardarEstado es la función del context que guarda la modificación.
    cerrar es la función encargada de ocultar el dialogo.
*/
function StateChange({ item, guardarEstado, verDetalle, cerrar }) {
  //state del requerimiento.
  const [req, setReq] = useState({ ...item });
  //const [req, setReq] = useState({ ...item, observAut: "" });
  //state del importe sin impuestos.
  const [importeSinImpuestos, setImporteSinImpuestos] = useState(0);
  //States para el dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  //State para estados del requerimiento
  const [opciones, setOpciones] = useState(
    ifState(item.state, item.tDocCodFor)
  );

  const [disabledButton, setDisabledButton] = useState(false);

  const enabled = {
    cursor: "pointer",
  };

  const disabled = {
    cursor: "not-allowed",
    display: "none",
  };

  /*
    Al renderizar el componente, recupera los productos del requerimiento seleccionado.
    */
  useEffect(() => {
    async function fp() {
      try {
        const products = await verDetalle(item.tDocCodFor, item.tDocNroFor);

        let importeTotal = 0;

        // Recorrer el array y sumar los precios
        products.forEach((product) => {
          if (product.bonus1 && product.bonus1 !== 0) {
            importeTotal +=
              product.quantity *
              (product.price - product.price * (-product.bonus1 / 100));
          } else {
            importeTotal += product.price * product.quantity;
          }
        });

        setImporteSinImpuestos(importeTotal);
      } catch (error) {
        console.log("error");
      }
    }
    if(item.tDocCodFor === "OC" || item.tDocCodFor === "OCP"){
      fp();
    }
  }, []);

  const handleChangeObservationsAut = (data) => {
    setReq({ ...req, observAut: data });
  };

  /* 
        Función encargada de guardar la modificación del requerimiento y lanzar una alerta.
        Donde r es el requerimiento con el nuevo estado.
    */
  const saveReq = async (r) => {
    setDisabledButton(true);
    const resp = await guardarEstado(r, r.state, r.observAut);
    console.log(resp);
    Swal.fire(
      "Nuevo Estado: " + r.state,
      "El Estado fue Modificado Correctamente <br> <strong>" +
        resp.message +
        " </strong> ",
      "success"
    );
    setDisabledButton(false);
    cerrar(null);
  };

  return (
    <div className="container-gr-state">
      <div className="label-state-icon-r">
        <FontAwesomeIcon
          className="state-icon-r"
          icon={faXmark}
          onClick={() => cerrar(null)}
        />
      </div>
      <div className="container-state">
        <div className="label-state-data">
          <strong>{req.tDocCodFor}</strong>&nbsp; - {req.tDocNroFor}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>
            Estado Actual:&nbsp;
          </strong>{" "}
          {item.state}
        </div>
        {(item.tDocCodFor.includes("OC") || item.tDocCodFor.includes("OCP")) &&
        (req.state === "AUTORIZADO" || req.state === "RECHAZADO") ? (
          <div>
            <div className="label-state-data">
              <strong>Fecha de Alta:&nbsp;</strong>{" "}
              {item.tDocCodFor === "REQ"
                ? moment.utc(item.fecAlt).format("DD-MM-YYYY")
                : moment.utc(item.fecAltERP).format("DD-MM-YYYY")}
            </div>
            <div className="label-state-data">
              <strong>Fecha de Movimiento:&nbsp;</strong>{" "}
              {moment.utc(item.fchMov).format("DD-MM-YYYY")}
            </div>
            <div className="label-state-data">
              <strong>Proveedor:&nbsp;</strong> {item.provider}
            </div>
            <div className="label-state-data">
              <strong>Moneda de Emsión:&nbsp;</strong> {item.emissionCoin}
            </div>
            <div className="label-state-data">
              <strong>Importe sin Impuesto:&nbsp;</strong>${" "}
              {importeSinImpuestos}
            </div>
            <div className="label-state-data">
              <strong>Info Adicional:&nbsp;</strong>
              {item.observ}
            </div>
          </div>
        ) : null}
        <div className="container-state-list">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            direction="down"
            className="dropdown-userTypes"
          >
            <DropdownToggle caret>
              {req.state === "" ? "Seleccione un estado" : req.state}
            </DropdownToggle>
            <DropdownMenu>
              {opciones.map(function (itemType, index) {
                return (
                  <article key={itemType}>
                    <DropdownItem
                      onClick={() => setReq({ ...req, state: itemType })}
                    >
                      {itemType}
                    </DropdownItem>
                  </article>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div>
          {req.state === "AUTORIZADO" || req.state === "RECHAZADO" ? (
            <div className="container-observ-aut">
              <div className="container-r-observ-aut-title">Observaciones:</div>
              <div className="container-input-observ-aut">
                <div>
                  <textarea
                    placeholder="Ingrese las observaciones"
                    className="r-item-observ-aut-input"
                    style={{ fontWeight: 600 }}
                    value={req.observAut}
                    onChange={(data) =>
                      handleChangeObservationsAut(data.target.value)
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="btn-state-container">
          <button
            className="btn-state"
            style={disabledButton ? disabled : enabled}
            onClick={() => saveReq(req)}
          >
            Cambiar Estado
          </button>
        </div>
      </div>
    </div>
  );
}

export default StateChange;
