//Componentes generales
import React from "react";

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBox
} from "@fortawesome/free-solid-svg-icons";

    const RenderRow = ({ index, style, productos = [], filter }) => {
            if (filter === '') {
                return (
                    <div style={style} className='container-p-list'>
                        <li>
                            <div className='container-p-list-item'>
                                <div className="container-p-list-item-horizontal">
                                    <div className='list-item-icon-p'><FontAwesomeIcon icon={faBox} /></div>
                                    <div className="label-title-products">Tipo Produto: <div className="label-products">{productos[index].tipPro}</div></div>
                                    <div className="label-title-products">Código Articulo: <div className="label-products">{productos[index].artCod}</div> </div>
                                    <div className="label-title-products">Codigo Reducido: <div className="label-products">{productos[index].codRed}</div> </div>
                                    <div className="label-title-products">Descripción: <div className="label-products">{productos[index].descrp}</div></div>
                                </div>
                            </div>
                        </li>
                    </div>);
            }else{
                const productos_filters = productos.filter(p => p.descrp.toLowerCase().includes(filter.toLowerCase()) || p.codRed.toLowerCase().includes(filter.toLowerCase()));
                return (
                    <div style={style} className='container-p-list'>
                        <li>
                            <div className='container-p-list-item'>
                                <div className="container-p-list-item-horizontal">
                                    <div className='list-item-icon-p'><FontAwesomeIcon icon={faBox} /></div>
                                    <div className="label-title-products">Tipo Produto: <div className="label-products">{productos_filters[index].tipPro}</div></div>
                                    <div className="label-title-products">Código Articulo: <div className="label-products">{productos_filters[index].artCod}</div> </div>
                                    <div className="label-title-products">Codigo Reducido: <div className="label-products">{productos_filters[index].codRed}</div> </div>
                                    <div className="label-title-products">Descripción: <div className="label-products">{productos_filters[index].descrp}</div></div>
                                </div>
                            </div>
                        </li>
                    </div>);
            }
};

export default RenderRow