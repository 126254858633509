//Componentes generales
import { useNavigate } from 'react-router-dom';
import Routes_gral from '../../utils/Routes_gral';

/*
    Componente que previene el acceso por URL sin haber iniciado sesión.
    Redirige al Login.
*/
const Error404 = () => {

    //Inicializo navegacion
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate(Routes_gral.login);
    };
      
    return (
        <div>
            <h1>Error: Necesitas iniciar sesión para acceder a esta página</h1>
            <button onClick={handleLoginClick}>Ir al inicio de sesión</button>
        </div>
        );
};

export default Error404;
