import { 
    ACTUALIZAR_PRODUCTOS,
    ACTUALIZAR_PRODUCTOS_DB,
    CARGANDO_PROD,
    ERROR_PROD,
    LIMPIAR_ERROR
} from "./productsAction";

const productsReducer = (state, action) => {
    switch(action.type){

        case ACTUALIZAR_PRODUCTOS:
        case ACTUALIZAR_PRODUCTOS_DB: {
            return {
                ...state,
                products: action.payload,
                cargando: false,
                error: ""
            }
        }

        case CARGANDO_PROD: {
            return {
                ...state,
                cargando: true,
                error: ""
            }
        }

        case ERROR_PROD: {
            return {
                ...state,
                cargando: false,
                error: action.payload
            }
        }

        case LIMPIAR_ERROR: {
            return {
                ...state,
                error: ""
            }
        }

    }
}

export default productsReducer;