export const PRIORITY =
[    "Media",
   "Alta",
    "Urgente",]
;

export const PRIORITY_UNDER = [
    0,1,2
];

export const PRIORITYIN = {
    "Media" : 0,
    "Alta" : 1,
    "Urgente" : 2,
};

export const PRIORITYOUT = {
    0 : "Media",
    1 : "Alta",
    2 : "Urgente"
};

export const PRIORITYA = [
    {id:0,  descr: "Media"},
    {id:1,  descr:"Alta"},
    {id:2,  descr:"Urgente"}
];