import { 
    ACTUALIZAR_SECTORES_REQS,
    ACTUALIZAR_SECTORES_REQS_LIST,
    ACTUALIZAR_SECTORES_REQS_DB,
    CARGANDO_SECTORES_REQS,
    ERROR_SECTORES_REQS,
    LIMPIAR_ERROR,
} from "./reqsSectorsAction";

const reqsSectorsReducer = (state, action) => {
    switch(action.type){

        case ACTUALIZAR_SECTORES_REQS:
        case ACTUALIZAR_SECTORES_REQS_DB: {
            return {
                ...state,
                reqsSectors: action.payload,
                cargando: false,
                error: ""
            }
        }

        case ACTUALIZAR_SECTORES_REQS_LIST:
            {
            return {
                ...state,
                error: "",
                reqsSectorsList: action.payload
            }
        }

        case CARGANDO_SECTORES_REQS: {
            return {
                ...state,
                cargando: true,
                error: ""
            }
        }

        case ERROR_SECTORES_REQS: {
            return {
                ...state,
                cargando: false,
                error: action.payload
            }
        }

        case LIMPIAR_ERROR: {
            return {
                ...state,
                error: ""
            }
        }

    }
}

export default reqsSectorsReducer;