import React from 'react';


import Select, { StylesConfig } from 'react-select';


function DropDownPicker ({options, onChange, value, isDisabled})  {


  return (
      <Select
          placeholder={"Seleccione usuarios pertenecientes al grupo"}
          closeMenuOnSelect={true}
          closeMenuOnScroll={false}
          styles={{
              multiValueLabel: (base) => ({
                ...base,
                backgroundColor: "#f8b03d",
                color: 'white',
                borderRadius: "none",
                fontSize: "1rem"
              }), 
              multiValueRemove: (base) => ({
                  ...base,
                  backgroundColor: "#f8b03d",
                  color: 'white',
                  borderRadius: "none",
                  fontSize: "1rem"
                }),
            }}
          isMulti
          options={options}
          onChange={(value) => onChange(value)}
          defaultValue={value}
          isDisabled={isDisabled}
      />
    );
}
export default DropDownPicker;