//Componentes generales
import React, { useContext } from 'react';

//Estilos
import './GrupoInfo.css';

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAddressCard,
    faUserGroup
} from "@fortawesome/free-solid-svg-icons";



import DropDownPicker from '../DropDownPicker/DropDownPicker';

function GrupoInfo({ data = undefined, minimizar }) {


    return (
        <div className='container-gr-profile'>
            <div className='label-profile-icon'><FontAwesomeIcon icon={faUserGroup} /></div>
            <div className='container-profile'>
                <div className='label-profile'>Nombre: <div className='label-profile-data'>{(data) ? data.group.name : "Undefined"}</div></div>
                <div className='label-profile'>Cantidad de Usuarios:  <div className='label-profile-data'>{data.group.cantUser}</div></div>
            </div>
            <div className='container-form'>
                <DropDownPicker
                    value={data.members}
                    isDisabled={true}
                />
            </div>

            <div className='btn-profile-container'><button className='btn-profile' onClick={minimizar}>Aceptar</button></div>
        </div>
    );
}

export default GrupoInfo