//Componentes generales
import React, { useState, useEffect, createRef } from "react";

// Estilos
import styles from "./AutoCompleteV2.module.css";

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown,
} from "@fortawesome/free-solid-svg-icons";


/*
    Componente encargado del autocompletado de producto en la carga de un nuevo requerimiento.
    Se encarga de buscar el producto seleccionado en la lista desplegable y setearlo.
*/
export default function AutocompleteV2(props) {

    const { placeholder, value = "", setValue, dataSuggestions, width = "100%", height = "20px", disabled = false, focus = false, ref = undefined } = props;

    const [status, setStatus] = useState("init")
    const [state, setState] = useState({
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: [],
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // Set user selection
        selection: "",
        id: "",
        value: "",
        status: "init" // error || init || success
    });

    /*
        State para efecto de expansion de la casilla de texto.
    */
    const [expanded, setExpanded] = useState(false);

    //Si hay un hover sobre la casilla, se setea en true.
    function handleMouseEnter() {
        setExpanded(true);
    }

    //Si no hay mas hover sobre la casilla, se setea en false.
    function handleMouseLeave() {
        setExpanded(false);
    }


    //Scroll con Flechas
    let refList = createRef();
    const [position, setPosition] = useState(0);

    useEffect(() => {
        if (disabled === true) setStatus("init");
    }, [disabled, state]);

    useEffect(() => {

        if (state.id === "" && state.value !== "" && state.filteredSuggestions.length <= 1) {
            setStatus("error");
        } else if (state.id !== "" && state.value !== "") {
            setStatus('success');
        } else {
            setStatus('init');
        }

    }, [state]);

    useEffect(() => {

        if (value !== "" && value.includes('~')) {
            setState({ ...state, id: value.split('~')[0], value: value.split('~')[1] })
        }
        else if (value === "" && state.value !== '' && state.id !== '') {
            setState({ ...state, id: "", value: "" })
        }
        // eslint-disable-next-line
    }, [value]);


    useEffect(() => {
        setPosition(0);
    }, [state.filteredSuggestions])

    const handleSuggestions = (text, data) => {

        let filtered = data.filter((s) => `${s.code} ${s.descrp} `.toUpperCase().indexOf(text.toUpperCase()) > -1);
        
        if (searchDescrp(text, data) === true && filtered.length === 1) {
            // Toma el value como un string
            setValue(`${filtered[0].code} ~ ${filtered[0].descrp}`);
            setState({ ...state, id: filtered[0].code, value: filtered[0].descrp, showSuggestions: false, status: 'success' });
        } else {

            if (filtered.length === 0 || text === "") {
                // Toma el value como un string
                setState({ ...state, filteredSuggestions: filtered, value: text, id: "", showSuggestions: false, activeSuggestion: 0 })
                setValue("");
            } else {
                setState({ ...state, filteredSuggestions: filtered, value: text, showSuggestions: (text !== "") ? true : false, activeSuggestion: 0 })
            }
        }
    }

    /*
        Manejador ante cambio en la casilla de texto. 
        Llama a manejador de sugerencias (productos filtrados segun lo ingresado en la casilla).
            Donde e es el valor de texto ingresado por el usuario.
    */
    const onChange = async (e) => {
        let text = e.currentTarget.value;
        handleSuggestions(text, dataSuggestions);
    }

    /* 
        Manejador ante click de selección de alguna de las sugerencias.
            Donde e es la sugerencia seleccionada.
    */
    const onClick = (e) => {
        let code = e.currentTarget.value;
        let descrp = e.currentTarget.innerText;
        setValue(descrp);
        setState({ ...state, id: descrp.split("~")[0], value: descrp.split("~")[1], status: "success", showSuggestions: false });
    }

    /*
        Manjeador ante el manejo por teclas. Contempla varios casos de manipulación.
    */
    const onKeyDown = (e) => {

        const { activeSuggestion, filteredSuggestions } = state;

        let lengthLista = state.filteredSuggestions.length * 40;
        let resto = (state.filteredSuggestions.length * 40) - 160;

        // User pressed the up arrow
        if (e.keyCode === 38) {

            if (activeSuggestion === 0) return;
            setState({ ...state, activeSuggestion: activeSuggestion - 1 });

            if (position !== 0) setPosition(position + 40);
        }
        // User pressed the down arrow llegas solo al ultimo
        else if (e.keyCode === 40) {

            if (state.value === "") {

            } else {

                if (activeSuggestion === filteredSuggestions.length - 1) return;
                setState({ ...state, activeSuggestion: activeSuggestion + 1 });

                if (activeSuggestion >= 3 && position > -resto) setPosition(position - 40);
            }

        }

        // User pressed the enter keyCode
        else if (e.keyCode === 13) {

            if (filteredSuggestions && filteredSuggestions.length === 0) return

            if (filteredSuggestions[activeSuggestion] !== undefined) {
                setValue(`${filteredSuggestions[activeSuggestion].code} ~ ${filteredSuggestions[activeSuggestion].descrp}`);
                handleSuggestions(filteredSuggestions[activeSuggestion].descrp, filteredSuggestions);
                setPosition(0);
            }

        }

    }

    /*
        Manejador ante griseado de casilla.
    */
    const onBlur = () => {
        if (state.value === "") {
            setState({ ...state, status: 'error' });
        }
    }

    /* 
        Manejador ante selección de la casilla de texto.
    */
    const onFocus = () => {
        if (state.value === "") {
            setState({ ...state, status: 'init' });
        }
    }

    /*
        Encargado de determinar si lo que va ingresando el usuario en la casilla de texto existe.
            Donde text es lo ingresado.
            Donde data es la lista a filtrar.
    */
    const searchDescrp = (text, data) => {
        let resp = data.filter((s) => s.descrp.toUpperCase() === text.toUpperCase());
        return (resp.length === 1) ? true : false
    }


    return (
        <div className={styles.container}>

            <div
                className={`${styles.centerInput} ${styles[status]} ${expanded ? styles.expand : ""}`}
                style={{ width, height }}

            >

                <div className={`${styles.codeCamp} `}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {value !== "" ?
                        <span>
                            {state.id}
                        </span>
                        :
                        <span>-</span>
                    }

                </div>

                <input
                    className={styles.autoCompleteInput}
                    type="text"
                    name={props.name || "userInput"}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    value={state.value}
                    placeholder={placeholder}
                    autoComplete="off"
                    disabled={disabled === true ? true : false}
                    autoFocus={(focus === true) ? true : false}
                    ref={ref}

                />

                <div className={`${styles.containerSuggestions} ${(state.showSuggestions === false) && styles.noVisible}`}>
                    <ul className={`${styles.suggestions}`} style={{ top: `${position}px` }} ref={refList}>
                        {state.filteredSuggestions.length > 0 &&
                            (state.filteredSuggestions.map((item, index) => {
                                return (
                                    <li onClick={onClick} value={item.code} key={index} className={(index === state.activeSuggestion) ? styles.suggestionActive : ""}>{`${item.code} ~ ${item.descrp}`}</li>
                                )
                            }))
                        }
                    </ul>
                    {(state.filteredSuggestions.length > 4 && state.activeSuggestion < state.filteredSuggestions.length - 1) &&

                        <div style={{ position: 'absolute', width: 20, height: 15, bottom: 0, right: '50%', transform: 'translateX(50%)' }}>
                            <FontAwesomeIcon icon={faCaretDown} color="#FF845B" />
                        </div>

                    }
                </div>


            </div>

            {status === "error" && <p className={styles.errorMsg}>Ingrese un valor valido</p>}

        </div>

    );

}
