import { 
    ACTUALIZAR_RESPONSABLES,
    ACTUALIZAR_RESPONSABLES_LIST,
    ACTUALIZAR_RESPONSABLES_DB,
    CARGANDO_RESPONSABLES,
    ERROR_RESPONSABLES,
    LIMPIAR_ERROR,
} from "./responsiblesAction";

const responsiblesReducer = (state, action) => {
    switch(action.type){

        case ACTUALIZAR_RESPONSABLES:
        case ACTUALIZAR_RESPONSABLES_DB: {
            return {
                ...state,
                responsibles: action.payload,
                cargando: false,
                error: ""
            }
        }

        case ACTUALIZAR_RESPONSABLES_LIST:
            {
            return {
                ...state,
                error: "",
                responsiblesList: action.payload
            }
        }

        case CARGANDO_RESPONSABLES: {
            return {
                ...state,
                cargando: true,
                error: ""
            }
        }

        case ERROR_RESPONSABLES: {
            return {
                ...state,
                cargando: false,
                error: action.payload
            }
        }

        case LIMPIAR_ERROR: {
            return {
                ...state,
                error: ""
            }
        }

    }
}

export default responsiblesReducer;