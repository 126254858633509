//Componentes generales
import React, { useReducer } from "react";
import applicantsReducer from "./applicantsReducer.js";
import applicantsContext from "./applicantsContext.js";

//Axios
import clienteAxios from "../../config/axios.js";

//Rutas de api
import { getAbmApplicants, uploadAbmApplicant } from "../../api/abmApplicant.js"

//Acciones
import { 
    ACTUALIZAR_SOLICITANTES,
    ACTUALIZAR_SOLICITANTES_LIST,
    ACTUALIZAR_SOLICITANTES_DB,
    CARGANDO_SOLICITANTES,
    ERROR_SOLICITANTES,
    LIMPIAR_ERROR
} from "./applicantsAction.js";

/* 
    State encargado de la gestión de solicitantes.
*/
const ApplicantsState = props => {

    //Valores iniciales
    const initialState = {
        applicants: [],
        applicantsList: [],
        cargando: false,
        error: ""
    };

    const [state, dispatch] = useReducer(applicantsReducer, initialState);

    /* 
        Función recuperar los solicitantes y a su vez actualizarlos.
    */
    const actualizarApplicants = async () => {

        try{
            const resp = await getAbmApplicants();

            dispatch({
                type: ACTUALIZAR_SOLICITANTES,
                payload: resp.data.data
            })

            return resp.data.data;

        }catch(error){
            console.log(error);
        }
    }

    /* Función para recuperar los solicitantes y actualizar la lista de solicitantes */
    const actualizarApplicantsList = async () => {

        try{
            const resp = await getAbmApplicants();

            dispatch({
                type: ACTUALIZAR_SOLICITANTES_LIST,
                payload: resp.data.data
            })

            return resp.data.data;

        }catch(error){
            console.log(error);
        }
    }

    /* 
        Función encargada de sobreescribir la base de datos de solicitantes con la propia de Softland.
            Donde userID es el ID del usuario en sesión.
                  userName es el nombre del usuario en sesión.
    */
    const actualizarApplicantsDB = async (userID, userName) => {

        try{
            console.log("ENTRO AL ACTUALIZAR SOLICITANTES");
            await uploadAbmApplicant(userID, userName);
            const resp = await getAbmApplicants();
            dispatch({
                type: ACTUALIZAR_SOLICITANTES_DB,
                payload: resp.data.data
            });

        return {status: true, msg: "Se actualizo con exito la base de datos."};

        }catch(error){
            console.log(error);
            dispatch({
                type: ERROR_SOLICITANTES,
                payload: error.response.data.errMsg
            });

            return {status: false, msg: error.response.data.errMsg};
        }
    }

    const cargando =  () => {
        dispatch({
            type: CARGANDO_SOLICITANTES
        })
    }

    const limpiarError = () => {
        dispatch({
            type: LIMPIAR_ERROR
        })
    }

    return(
        <applicantsContext.Provider
            value={{
                applicants: state.applicants,
                applicantsList: state.applicantsList,
                isCargando: state.cargando,
                error: state.error,
                actualizarApplicants,
                actualizarApplicantsList,
                actualizarApplicantsDB,
                cargando,
                limpiarError
            }}
        >

            {props.children}

        </applicantsContext.Provider>
    )
    
}

export default ApplicantsState;