//Componentes generales
import React, { useContext, useEffect } from "react";
import ReqSectorList from "../../components/ReqSectorList/ReqSectorList";

//Context
import reqsSectorsContext from "../../context/sectoresReqs/reqsSectorsContext";
import authContext from '../../context/authentication/authContex';

// Alertas
import Swal from 'sweetalert2'

//Estilos
import './ReqSector.css';

/* 
    Vista donde se gestiona toda la lógica de listado de sectores de requerimientos.
        Donde props no se encuentra en uso.
*/
function ReqSector(props) {

    //Context Auth
    const aContext = useContext(authContext);
    const { usuario } = aContext;


    //Context sectores de requerimientos
    const rSContext = useContext(reqsSectorsContext);
    const { reqsSectors, actualizarReqsSectors, actualizarReqsSectorsDB } = rSContext;

    /* 
        Disparador que se ejecuta ante la renderización del componente. El mismo se encarga de actualizar los sectores de requerimientos disponibles del context.
    */
    useEffect(() => {
        const fn = async () => {
            await actualizarReqsSectors();
        }
        fn();
    }, []);

    /* 
        Manejador que se encarga de la actualización de sectores de requerimientos que sobreescribe los sectores de requerimientos actuales por los recuperados de Softland.
    */
    const handlerActualizarReqsSectors = async () =>
    { 
        Swal.fire({
            title: 'Desea actualizar la lista de sectores de requerimientos?',
            text: "Se traeran los datos de Softland para sobreescribir la base de datos de la aplicación.",
            showCancelButton: true,
            confirmButtonText: 'Actualizar',
            cancelButtonText: 'No, cancelar',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              return await actualizarReqsSectorsDB(usuario._id, usuario.userName)
                .then(response => {
                  if (!response.status) {
                    throw new Error(response.msg)
                  }
                  return response;
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
                    Swal.fire(
                        'Actualizado!',
                        'La base de datos ha sido actualizada correctamente.',
                        'success'
                    )
            }
          })
    }


    return (
        <div className="container-list">
            <div className="actualiza-reqs-sectors-container">
                <button className="actualizar-reqs-sectors-btn" onClick={handlerActualizarReqsSectors}>Actualizar Sectores de Requerimientos</button>
            </div>
            <ReqSectorList reqsSectors={reqsSectors} />
        </div>
    );
}

export default ReqSector