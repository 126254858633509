export const ACTUALIZAR_LIST_USER = "ACTUALIZAR_LIST_USER";
export const ACTUALIZAR_LIST_USER_VIEW = "ACTUALIZAR_LIST_USER_VIEW"; 
export const VER_FORM_USER = "VER_FORM_USER";
export const VER_USER = "VER_USER";
export const SELECCIONAR_USER = "SELECCIONAR_USER";
export const ESCUCHA_MODIFICAR_USER = "ESCUCHA_MODIFICAR_USER";
export const MODIFICAR_USER = "MODIFICAR_USER";
export const AGREGAR_USER = "AGREGAR_USER";  
export const ELIMINAR_USER = "ELIMINAR_USER";
export const ERROR = "ERROR"; 
export const LIMPIAR_ERROR = "LIMPIAR_ERROR";