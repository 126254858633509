
//Types
import { 
    MODIFICAR_FILTROS,
    CARGANDO_CONTROL,
    REINICIAR_FILTROS,
    CAMBIAR_FROM,
    SELECCIONAR_TDOC,
    ACTUALIZAR_TDOC,
    NOTIFICACION
} from "./controlGLBActions";

const controlGLBReducer = (state, action) => {
    switch(action.type){

        case MODIFICAR_FILTROS: {
            return {
                ...state,
                filtros: {...state.filtros, ...action.payload.filtros},
            }
        }
        
        case REINICIAR_FILTROS: {
            return {
                ...state,
                filtros: action.payload,
            }
        }


        case CARGANDO_CONTROL: {
            return {
                ...state,
                cargando: action.payload
            }
        }

        case CAMBIAR_FROM: {
            return {
                ...state,
                from: action.payload
            }
        }

        case SELECCIONAR_TDOC: {
            return {
                ...state,
                tDocSelected: action.payload
            }
        }

        case ACTUALIZAR_TDOC: {
            return {
                ...state,
                tDocSelected: action.payload
            }
        }

        case NOTIFICACION: {
            return {
                ...state,
                notificacion: action.payload
            }
        }


        default:
            return state;
    }
}

export default controlGLBReducer;