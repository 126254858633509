//Componentes generales
import React, { useReducer } from "react";
import reqsSectorsReducer from "./reqsSectorsReducer.js";
import reqsSectorsContext from "./reqsSectorsContext.js";

//Axios
import clienteAxios from "../../config/axios.js";

//Rutas de api
import { getAbmReqSector, uploadAbmReqSector } from "../../api/abmReqSector.js"

//Acciones
import { 
    ACTUALIZAR_SECTORES_REQS,
    ACTUALIZAR_SECTORES_REQS_LIST,
    ACTUALIZAR_SECTORES_REQS_DB,
    CARGANDO_SECTORES_REQS,
    ERROR_SECTORES_REQS,
    LIMPIAR_ERROR
} from "./reqsSectorsAction.js";

/* 
    State encargado de la gestión de sectores de requerimiento.
*/
const ReqsSectorsState = props => {

    //Valores iniciales
    const initialState = {
        reqsSectors: [],
        reqsSectorsList: [],
        cargando: false,
        error: ""
    };

    const [state, dispatch] = useReducer(reqsSectorsReducer, initialState);

    /* 
        Función recuperar los sectores de requerimientos y a su vez actualizarlos.
    */
    const actualizarReqsSectors = async () => {

        try{
            const resp = await getAbmReqSector();

            dispatch({
                type: ACTUALIZAR_SECTORES_REQS,
                payload: resp.data.data
            })

            return resp.data.data;

        }catch(error){
            console.log(error);
        }
    }

    /* Función para recuperar los sectores de requerimientos y actualizar la lista de sectores de requerimientos */
    const actualizarReqsSectorsList = async () => {

        try{
            const resp = await getAbmReqSector();

            dispatch({
                type: ACTUALIZAR_SECTORES_REQS_LIST,
                payload: resp.data.data
            })

            return resp.data.data;

        }catch(error){
            console.log(error);
        }
    }

    /* 
        Función encargada de sobreescribir la base de datos de sectores de requerimientos con la propia de Softland.
            Donde userID es el ID del usuario en sesión.
                  userName es el nombre del usuario en sesión.
    */
    const actualizarReqsSectorsDB = async (userID, userName) => {

        try{
            console.log("ENTRO AL ACTUALIZAR SECTORES DE REQUERIMIENTOS");
            await uploadAbmReqSector(userID, userName);
            const resp = await getAbmReqSector();
            console.log(resp);
            dispatch({
                type: ACTUALIZAR_SECTORES_REQS_DB,
                payload: resp.data.data
            });

        return {status: true, msg: "Se actualizo con exito la base de datos."};

        }catch(error){
            console.log(error);
            dispatch({
                type: ERROR_SECTORES_REQS,
                payload: error.response.data.errMsg
            });

            return {status: false, msg: error.response.data.errMsg};
        }
    }

    const cargando =  () => {
        dispatch({
            type: CARGANDO_SECTORES_REQS
        })
    }

    const limpiarError = () => {
        dispatch({
            type: LIMPIAR_ERROR
        })
    }

    return(
        <reqsSectorsContext.Provider
            value={{
                reqsSectors: state.reqsSectors,
                reqsSectorsList: state.reqsSectorsList,
                isCargando: state.cargando,
                error: state.error,
                actualizarReqsSectors,
                actualizarReqsSectorsList,
                actualizarReqsSectorsDB,
                cargando,
                limpiarError
            }}
        >

            {props.children}

        </reqsSectorsContext.Provider>
    )
    
}

export default ReqsSectorsState;