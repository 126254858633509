import io from "socket.io-client";
import { getRealTimeServerURL, getServerURL } from "../../config/config";
import { createContext } from 'react';
import { useEffect } from "react";
import { useState } from "react";

export const SocketContext = createContext();

const Socket = props => {
    
    const[server, setServer] = useState();
 
    useEffect(() => {
        const newSocket = io(getRealTimeServerURL(), {
            path: '/rt'
        });
        console.log(newSocket);
        setServer(newSocket);


        return () => newSocket.close();
    },[])



    return(
        <SocketContext.Provider value={server}>
            {props.children}
        </SocketContext.Provider>
    )
    
}

export default Socket;