//Roles
import { ADMIN, CONTROL, REQUERIMIENTO, MODIFICAR_REQ} from './roles'

//Habilitados para ver solapa nav de productos.
export const VER_PRODUCTOS = [ADMIN, CONTROL];
//Habilitados para ver todos los requerimientos de la lista y no solo los que fueron creados por el usuario.
export const VER_TODOS_REQUERIMIENTOS = [ADMIN, CONTROL];
//Habilitados para modificar el estado de autorización de un requerimiento.
export const MODIFICAR_ESTADO_REQUERIMIENTO = [ADMIN, CONTROL];
//Habilitados para editar notas privadas de un requerimiento ya creado.
export const EDITAR_NOTAS_PRIVADAS = [ADMIN, CONTROL];
//Habilitados para editar notas publicas de un requerimiento ya creado.
export const EDITAR_NOTAR_PUBLICAS = [ADMIN, CONTROL];
//Habilitados para usar la pantalla de edición de un requerimiento ya creado.
export const EDITAR_REQUERIMIENTO = [ADMIN, MODIFICAR_REQ];
//Habilitados para ver solapa nav de usuario.
export const VER_USUARIOS = [ADMIN];
//Habilitados para ver solapa nav de solicitantes.
export const VER_SOLICITANTES = [ADMIN, CONTROL];
//Habilitados para ver solapa nav de responsables.
export const VER_RESPONSABLES = [ADMIN, CONTROL];
//Habilitados para ver solapa nav de sector de requerimientos.
export const VER_SECTORES_REQUERIMIENTOS = [ADMIN, CONTROL];
//Habilitados para ver solapa nav de sector de depósitos.
export const VER_DEPOSITOS = [ADMIN, CONTROL];
//Habilitados para reenviar un requerimiento desde la lista de requerimientos.
export const REENVIO_MOSCUZZA = [ADMIN, CONTROL];

export const VER_GRUPOS = [ADMIN];
