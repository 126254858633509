//Componentes generales
import { useState } from 'react';
import { useEffect, useContext } from 'react';
import GrupoList from '../../components/GrupoList/GrupoList';
import Swal from 'sweetalert2';

//Contextos
import gruposContext from '../../context/grupos/gruposContext';

function Grupos(props) {

    //Se Obtiene el usuario en sesion
    const gContext = useContext(gruposContext);
    const { gruposList, actualizarGroupList, agregarGroup, modificarGroup, eliminarGroup, enviarNotificacionPush } = gContext;


    const handlerEliminarGroup = async (idGroup) => {
        Swal.fire({
            title: 'Estas seguro que deseas eliminar este Grupo?',
            text: "Se eliminara permanentemente!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Borrar',
            cancelButtonText: 'No, cancelar',
            reverseButtons: false
        }).then( async (result) => {
            if (result.isConfirmed) {
                const resp = eliminarGroup(idGroup);
                console.log(resp.data);
                Swal.fire(
                    'Borrado!',
                    'El Grupo Fue Borrado Correctamente.',
                    'success'
                )

            } else {
                    if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'Cancelado',
                            'El Grupo no fue Borrado del Sistema',
                            'error'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'El Grupo no puede ser Borrado.',
                            'error'
                        )
        
                    }
                }
        });
    }

    const handlerAgregarGroup = async (group, usuariosId) => {
        try{

            await agregarGroup(group, usuariosId);
            Swal.fire({
                title: `Se creo un Nuevo Grupo`,
                icon: 'success',
                text: `El nombre de Grupo es : ${group.name}`,
                showConfirmButton: true,
                allowEnterKey: true
            });

        }catch(err){
            console.log(err);
            Swal.fire(
                'Error!',
                'El Grupo fue creado.',
                'error'
            )
        }
    }

    const handlerModificarGroup = async (id, updates) => {
        
        try{

            await modificarGroup(id, updates);
            Swal.fire({
                title: `Se modifico el Grupo correctamente`,
                icon: 'success',
                showConfirmButton: true,
                allowEnterKey: true
            });

        }catch(err){
            console.log(err);
            Swal.fire(
                'Error!',
                'El Grupo no fue modificado.',
                'error'
            )
        }
    }

    const handlerEnviarNotificacion = async (groupId, title, message) => {
        try{

            await enviarNotificacionPush(groupId, title, message);
            Swal.fire({
                title: `Se envio la notifiacion correctamente`,
                icon: 'success',
                showConfirmButton: true,
                allowEnterKey: true
            });

        }catch(err){
            console.log(err);
            Swal.fire(
                'Error!',
                'No se puede enviar notificaciones.',
                'error'
            )
        }
    }

    useEffect(() => {
        if(!gruposList) actualizarGroupList();
    },[]);

    return (
        <div className='container-list'>
            <div className='container-user-back'>
                <GrupoList
                    grupos={gruposList}
                    agregarGroup={handlerAgregarGroup}
                    modificarGroup={handlerModificarGroup}
                    eliminarGroup={handlerEliminarGroup}
                    enviarNotificacion={handlerEnviarNotificacion}
                />
            </div>
        </div>
    );
}

export default Grupos;