//Types
import { 

    ACTUALIZAR_LIST_USER,
    ACTUALIZAR_LIST_USER_VIEW,
    VER_FORM_USER,
    VER_USER,
    SELECCIONAR_USER,
    MODIFICAR_USER,
    AGREGAR_USER,
    ELIMINAR_USER,
    ERROR,
    LIMPIAR_ERROR

} from "./usuariosActions";

const usuariosReducer = (state, action) => {
    switch(action.type){

        case ACTUALIZAR_LIST_USER : {
            return {
                ...state,
                error: null,
                userList: action.payload
            }
        };

        case ACTUALIZAR_LIST_USER_VIEW : {
            return {
                ...state,
                error: null,
                userListView: action.payload
            }
        };


        case VER_FORM_USER: {
            return {
                ...state,
                verModificar: !state.verModificar,
                error: null,
                userSeleccionado: (!state.verModificar === true)? state.userSeleccionado : null
            }
        };

        case VER_USER: {
            return {
                ...state,
                verUser: !state.verUser,
                error: null,
                userSeleccionado: (!state.verUser === true)? state.userSeleccionado : null
            }
        };

        case SELECCIONAR_USER: {
            return {
                ...state,
                error: null,
                userSeleccionado: action.payload
            }
        };


        case MODIFICAR_USER: {
            if(state.userList !== null){
            return {
                ...state,
                userSeleccionado: null,
                verModificar: false,
                error: null,
                userList: state.userList.map(user => user._id === action.payload._id ? action.payload : user)
            }
            } else return {...state}
        };



        case AGREGAR_USER:{
            if(state.userList !== null){
                return {
                    ...state,
                    userSeleccionado: null,
                    verModificar: false,
                    verUser: false,
                    error: null,
                    userList: [...state.userList, action.payload]
                }
            } else return {...state}
        };

        case ELIMINAR_USER:{
            if(state.userList !== null){
                return {
                    ...state,
                    userSeleccionado: null,
                    verModificar: false,
                    error: null,
                    userList: state.userList.filter(user => user._id != action.payload)
                }
            } else return {...state}
        };

        case ERROR: {
            return {
                ...state,
                verModificar: true,
                verUser: false,
                error: action.payload
            }
        }

        case LIMPIAR_ERROR: {
            return {
                ...state,
                error: ""
            }
        }

        default:
            return state;
    }
}

export default usuariosReducer;