import { useEffect, useState } from 'react';

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";

// Estilos
import './SearchInput.css'

/* 
    Componente de boton flotante de lupa que funciona para filtrar por busqueda.
        Donde buscar es la función que llama para filtrar por lo ingresado por el usuario.
*/
function SearchInput({ defaultValue, buscar }) {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        // Si el valor por defecto no está vacío, expandimos la barra de búsqueda
        if (defaultValue !== "") {
          setExpanded(true);
        }
      }, [defaultValue]);

      const handleMouseEnter = () => {
        if (defaultValue === "") {
          setExpanded(false); // Solo contrae si defaultValue está vacío
        }
      };
    
      const handleFocus = () => {
        setExpanded(true); // Expande la barra de búsqueda al hacer clic o enfocar
      };

    return (
        <div
        className={`search-container add-search ${expanded ? 'expanded' : ''}`}
        onMouseEnter={handleMouseEnter}
        onFocus={handleFocus}
      >
            <FontAwesomeIcon icon={faMagnifyingGlass} className='add-search-cont' />
            <input placeholder='Buscar...' className='input-search-txt'
            value={defaultValue}
            onChange={data => buscar(data.target.value)}
            onFocus={() => setExpanded(true)} // Expande al hacer focus
            onBlur={() => setExpanded(false)} // Contrae al quitar el foco
            />
        </div>
    );
}

export default SearchInput