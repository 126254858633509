//Componentes generales
import React, { useState, useMemo, useContext } from "react";
import { FixedSizeList } from "react-window";
import SearchInput from "../searchInput/SearchInput";
import RenderRow from "../renderRows/RRResponsibles";
import windowSizeContext from "../../context/windowSize/windowSizeContext";

//Estilos
import "./ResponsibleList.css";

/*
    Componente encargado de la vista de los responsables disponibles.
    Donde responsables es la lista de responsables disponibles recuperados del context.
*/
function ResponsibleList({ responsibles = [] }) {
  //state de filtro.
  const [filter, setFilter] = useState("");

  // Calcula el valor del height según el tamaño de la pantalla y el tamaño del navbar
  const wSContext = useContext(windowSizeContext);
  let { height } = wSContext;

    /*
    Manejador ante cambios en filtro.
    Donde data es el texto ingresado por el usuario.
    */
  const handdlerSearchInput = (data) => {
    setFilter(data);
  };

  return (
    <div>
      <div style={{ marginLeft: "-300px" }}>
        <SearchInput
          defaultValue={filter}
          buscar={handdlerSearchInput}
        />
      </div>
      <div className="container-responsible-list">
        <FixedSizeList
          height={height - 210}
          itemCount={
            filter === ""
              ? responsibles.length
              : responsibles.filter(
                  (r) =>
                    r.name.toLowerCase().includes(filter.toLowerCase()) ||
                    r.idSql.toString().includes(filter)
                ).length
          }
          itemSize={80}
          width={"100%"}
        >
          {({ index, style }) => (
            <RenderRow
              index={index}
              style={style}
              responsibles={responsibles}
              filter={filter}
            />
          )}
        </FixedSizeList>
      </div>
    </div>
  );
}

export default ResponsibleList;
