export const BUSCAR_TDOCS = "BUSCAR_TDOCS";
export const MODIFICAR_FILTROS = "MODIFICAR_FILTROS";
export const CARGANDO_CONTROL = "CARGANDO_CONTROL";
export const REINICIAR_FILTROS = "REINICIAR_FILTROS";
export const CAMBIAR_VIEW_FROM = "CAMBIAR_VIEW_FROM";
export const SELECCIONAR_TDOC = "SELECCIONAR_TDOC"; 
export const ACTUALIZAR_TDOC = "ACTUALIZAR_TDOC";
export const AGREGAR_TDOC = "AGREGAR_TDOC";
export const APLICAR_FILTROS = "APLICAR_FILTROS";
export const INICIALIZAR_FILTROS = "INICIALIZAR_FILTROS";

//Nueva logica
export const MODIFICAR_FILTROS_ESTADOS = "MODIFICAR_FILTROS_ESTADOS"; 
export const MODIFICAR_FILTROS_RANGO = "MODIFICAR_FILTROS_RANGO";
export const INICIAR_FILTROS = "INICIAR_FILTROS"; 