import clienteAxios from "../config/axios";

export async function getAbmWarehouse () {
    return clienteAxios.get(`/abmWarehouse`);
}

export async function getAbmWarehouseByDeposito (deposito) {
    return clienteAxios.get(`/abmWarehouse/${deposito}`);
}

export async function uploadAbmWarehouse ( userID, userName) {
    return clienteAxios.post('/abmWarehouse/actualizar', {userID, userName});
}

export async function getAbmWarehouseSector () {
    return clienteAxios.get(`/abmWarehouseSector`);
}

export async function getAbmWarehouseSectorsByDeposito (deposito) {
    return clienteAxios.get(`/abmWarehouseSectorByDeposito/${deposito}`);
}

export async function getAbmWarehouseSectorByDepositoAndSector (deposito, sector) {
    return clienteAxios.get(`/abmWarehouseSectorByDepositoAndSector/${deposito}/${sector}`);
}

