//Estilos
import './FilterScreen.css'

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars
} from "@fortawesome/free-solid-svg-icons";

/* 
    Componente de icono flotante de "=" que funciona como "filtros".
        Donde verFiltro recibe el componente dialog a mostrar.
*/
function FilterScreen({verFiltro, disabled}) {
    return (
        <div className={`filter-container add-filter ${disabled ? 'disabled' : ''}`} onClick={() => verFiltro(true)}>
            <FontAwesomeIcon icon={faBars} className='add-filter-cont' />
            <div className="input-filter-txt">Filtros</div>
        </div>
    );
}

export default FilterScreen