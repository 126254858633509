//Componentes generales
import React, { useReducer } from "react";
import responsiblesReducer from "./responsiblesReducer.js";
import responsiblesContext from "./responsiblesContext.js";

//Axios
import clienteAxios from "../../config/axios.js";

//Rutas de api
import { getAbmResponsibles, uploadAbmResponsible } from "../../api/abmResponsible.js"

//Acciones
import { 
    ACTUALIZAR_RESPONSABLES,
    ACTUALIZAR_RESPONSABLES_LIST,
    ACTUALIZAR_RESPONSABLES_DB,
    CARGANDO_RESPONSABLES,
    ERROR_RESPONSABLES,
    LIMPIAR_ERROR
} from "./responsiblesAction.js";

/* 
    State encargado de la gestión de responsables.
*/
const ResponsiblesState = props => {

    //Valores iniciales
    const initialState = {
        responsibles: [],
        responsiblesList: [],
        cargando: false,
        error: ""
    };

    const [state, dispatch] = useReducer(responsiblesReducer, initialState);

    /* 
        Función recuperar los responsables y a su vez actualizarlos.
    */
    const actualizarResponsibles = async () => {

        try{
            const resp = await getAbmResponsibles();

            dispatch({
                type: ACTUALIZAR_RESPONSABLES,
                payload: resp.data.data
            })

            return resp.data.data;

        }catch(error){
            console.log(error);
        }
    }

    /* Función para recuperar los responsables y actualizar la lista de responsables */
    const actualizarResponsiblesList = async () => {

        try{
            const resp = await getAbmResponsibles();

            dispatch({
                type: ACTUALIZAR_RESPONSABLES_LIST,
                payload: resp.data.data
            })

            return resp.data.data;

        }catch(error){
            console.log(error);
        }
    }

    /* 
        Función encargada de sobreescribir la base de datos de responsables con la propia de Softland.
            Donde userID es el ID del usuario en sesión.
                  userName es el nombre del usuario en sesión.
    */
    const actualizarResponsiblesDB = async (userID, userName) => {

        try{
            console.log("ENTRO AL ACTUALIZAR RESPONSABLES");
            await uploadAbmResponsible(userID, userName);
            const resp = await getAbmResponsibles();
            dispatch({
                type: ACTUALIZAR_RESPONSABLES_DB,
                payload: resp.data.data
            });

        return {status: true, msg: "Se actualizo con exito la base de datos."};

        }catch(error){
            console.log(error);
            dispatch({
                type: ERROR_RESPONSABLES,
                payload: error.response.data.errMsg
            });

            return {status: false, msg: error.response.data.errMsg};
        }
    }

    const cargando =  () => {
        dispatch({
            type: CARGANDO_RESPONSABLES
        })
    }

    const limpiarError = () => {
        dispatch({
            type: LIMPIAR_ERROR
        })
    }

    return(
        <responsiblesContext.Provider
            value={{
                responsibles: state.responsibles,
                responsiblesList: state.responsiblesList,
                isCargando: state.cargando,
                error: state.error,
                actualizarResponsibles,
                actualizarResponsiblesList,
                actualizarResponsiblesDB,
                cargando,
                limpiarError
            }}
        >

            {props.children}

        </responsiblesContext.Provider>
    )
    
}

export default ResponsiblesState;