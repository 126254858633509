//Componentes generales
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

//Rutas
import Routes_gral from "../../utils/Routes_gral";

//Permisos
import {
  VER_USUARIOS,
  VER_PRODUCTOS,
  VER_GRUPOS,
  VER_SOLICITANTES,
  VER_RESPONSABLES,
  VER_SECTORES_REQUERIMIENTOS,
  VER_DEPOSITOS,
} from "../../utils/permisos";

//Estilos
import "./Navbar.css";

//Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faUsersBetweenLines,
  faHouse,
  faBasketShopping,
  faPeopleGroup,
  faPlusSquare,
  faUsers,
  faLocation,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

/*
  Componente encarado de las redirecciones de navegación de la pagina.
    Donde usuario es el usuario en sesión.
*/
const StyledNavbar_bottom = ({ usuario }) => {
  return (
    <div className="navbar-xx">
      <nav className="navbar-container-xx">
        <NavLink
          to={Routes_gral.home}
          //to={null}
          className="link navbar-item-xx"
        >
          <FontAwesomeIcon icon={faHouse} className="icon-navbar" />
          <div className="navbar-item-label-bottom-xx">Home</div>
        </NavLink>
        <NavLink
          to={Routes_gral.addReq}
          //to={null}
          className="link navbar-item-xx"
        >
          <FontAwesomeIcon icon={faPlusSquare} className="icon-navbar" />{" "}
          <div className="navbar-item-label-bottom-xx">Agregar</div>
        </NavLink>
        {usuario.userType.some((item) =>
          VER_PRODUCTOS.includes(item.typeName)
        ) && (
          <NavLink to={Routes_gral.product} className="navbar-item-xx">
            <FontAwesomeIcon icon={faBasketShopping} className="icon-navbar" />
            <div className="navbar-item-label-bottom-xx" color="white">
              Productos
            </div>
          </NavLink>
        )}
        {usuario.userType.some((item) =>
          VER_SOLICITANTES.includes(item.typeName)
        ) && (
          <NavLink to={Routes_gral.applicant} className="link navbar-item-xx">
            <FontAwesomeIcon icon={faPeopleGroup} className="icon-navbar" />{" "}
            <div className="navbar-item-label-bottom-xx">Solicitantes</div>
          </NavLink>
        )}

        {usuario.userType.some((item) =>
          VER_RESPONSABLES.includes(item.typeName)
        ) && (
          <NavLink to={Routes_gral.responsible} className="link navbar-item-xx">
            <FontAwesomeIcon icon={faPeopleGroup} className="icon-navbar" />{" "}
            <div className="navbar-item-label-bottom-xx">Responsables</div>
          </NavLink>
        )}

        {usuario.userType.some((item) =>
          VER_SECTORES_REQUERIMIENTOS.includes(item.typeName)
        ) && (
          <NavLink
            to={Routes_gral.reqSector}
            //to={null}
            className="link navbar-item-xx"
          >
            <FontAwesomeIcon icon={faLocation} className="icon-navbar" />{" "}
            <div className="navbar-item-label-bottom-xx">
              Sectores de Requerimientos
            </div>
          </NavLink>
        )}
        {usuario.userType.some((item) =>
          VER_DEPOSITOS.includes(item.typeName)
        ) && (
          <NavLink to={Routes_gral.warehouse} className="link navbar-item-xx">
            <FontAwesomeIcon icon={faWarehouse} className="icon-navbar" />{" "}
            <div className="navbar-item-label-bottom-xx">Depósitos</div>
          </NavLink>
        )}
        {usuario.userType.some((item) =>
          VER_USUARIOS.includes(item.typeName)
        ) && (
          <NavLink to={Routes_gral.user} className="link navbar-item-xx">
            <FontAwesomeIcon icon={faUsers} className="icon-navbar" />{" "}
            <div className="navbar-item-label-bottom-xx">Usuarios</div>
          </NavLink>
        )}
        {usuario.userType.some((item) =>
          VER_GRUPOS.includes(item.typeName)
        ) && (
          <NavLink to={Routes_gral.group} className="link navbar-item-xx">
            <FontAwesomeIcon
              icon={faUsersBetweenLines}
              className="icon-navbar"
            />{" "}
            <div className="navbar-item-label-bottom-xx">Grupos</div>
          </NavLink>
        )}
      </nav>
    </div>
  );
};
export default StyledNavbar_bottom;
