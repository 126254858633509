import clienteAxios from "../config/axios";

export async function getAbmReqSector () {
    return clienteAxios.get(`/abmReqSector`);
}

export async function uploadAbmReqSector ( userID, userName) {
    return clienteAxios.post('/abmReqSector/actualizar', {userID, userName});
}

export async function getAbmReqSectorByCodSem (codSem) {
    return clienteAxios.get(`/abmReqSector/${codSem}`);
}