export function getNewFormattedDate(){

    const now = new Date();

    // Obtener la diferencia en horas entre la zona horaria local (Argentina) y UTC
    const offset = -3; // Argentina está generalmente en UTC-3
    const localDate = new Date(now.getTime() + offset * 60 * 60 * 1000);

    // Formatear la fecha en formato ISO 8601 con el offset `+00:00`
    const formattedDate = localDate.toISOString().replace("Z", "+00:00");
    return  formattedDate
}