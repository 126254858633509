//Componentes generales
import React, { useReducer } from "react";
import warehousesReducer from "./warehousesReducer.js";
import warehousesContext from "./warehousesContext.js";

//Axios
import clienteAxios from "../../config/axios.js";

//Rutas de api
import { getAbmWarehouse, uploadAbmWarehouse } from "../../api/abmWarehouse.js"
import { getAbmWarehouseSector, uploadAbmWarehouseSector } from "../../api/abmWarehouse.js"

//Acciones
import { 
    ACTUALIZAR_DEPOSITOS,
    ACTUALIZAR_SECTORES,
    ACTUALIZAR_DEPOSITOS_DB,
    CARGANDO_DEPOSITOS,
    ERROR_DEPOSITOS,
    LIMPIAR_ERROR
} from "./warehousesAction.js";

/* 
    State encargado de la gestión de depósitos.
*/
const WarehousesState = props => {

    //Valores iniciales
    const initialState = {
        warehouses: [],
        sectors: [],
        cargando: false,
        error: ""
    };

    const [state, dispatch] = useReducer(warehousesReducer, initialState);

    /* 
        Función recuperar los depósitos y a su vez actualizarlos.
    */
    const actualizarWarehouses = async () => {

        try{
            const resp = await getAbmWarehouse();

            dispatch({
                type: ACTUALIZAR_DEPOSITOS,
                payload: resp.data.data
            })

            return resp.data.data;

        }catch(error){
            console.log(error);
        }
    }

    /* 
        Función recuperar los sectores de los depósitos y a su vez actualizarlos.
    */
        const actualizarSectors = async () => {

            try{
                const resp = await getAbmWarehouseSector();
    
                dispatch({
                    type: ACTUALIZAR_SECTORES,
                    payload: resp.data.data
                })
                return resp.data.data;
    
            }catch(error){
                console.log(error);
            }
        }

    /* 
        Función encargada de sobreescribir la base de datos de depósitos con la propia de Softland.
        Donde userID es el ID del usuario en sesión.
        userName es el nombre del usuario en sesión.
    */
    const actualizarWarehousesDB = async (userID, userName) => {

        try{
            console.log("ENTRO AL ACTUALIZAR DEPOSITOS");
            await uploadAbmWarehouse(userID, userName);
            const resp = await getAbmWarehouse();
            dispatch({
                type: ACTUALIZAR_DEPOSITOS_DB,
                payload: resp.data.data
            });

        return {status: true, msg: "Se actualizo con exito la base de datos."};

        }catch(error){
            console.log(error);
            dispatch({
                type: ERROR_DEPOSITOS,
                payload: error.response.data.errMsg
            });

            return {status: false, msg: error.response.data.errMsg};
        }
    }

    const cargando =  () => {
        dispatch({
            type: CARGANDO_DEPOSITOS
        })
    }

    const limpiarError = () => {
        dispatch({
            type: LIMPIAR_ERROR
        })
    }

    return(
        <warehousesContext.Provider
            value={{
                warehouses: state.warehouses,
                sectors: state.sectors,
                isCargando: state.cargando,
                error: state.error,
                actualizarWarehouses,
                actualizarSectors,
                actualizarWarehousesDB,
                cargando,
                limpiarError
            }}
        >

            {props.children}

        </warehousesContext.Provider>
    )
}

export default WarehousesState;