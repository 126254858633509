import { useEffect, useState } from "react";
import windowSizeContext from "./windowSizeContext";

const WindowSizeState = props =>{

    const [height,setHeight] = useState(window.innerHeight);

    const handleResize = () =>{
        setHeight(window.innerHeight);
    }

    useEffect(()=>{
        window.addEventListener('resize',handleResize);
        return () => window.removeEventListener('resize',handleResize);
    },[]);

    return (
        <windowSizeContext.Provider value={{height:height,setHeight}}>
            {props.children}
        </windowSizeContext.Provider>
    )

}

export default WindowSizeState;