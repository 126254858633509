//Types
import { 
    ACTUALIZAR_LIST_GROUP,
    MODIFICAR_GROUP,
    AGREGAR_GROUP,
    ELIMINAR_GROUP,
  } from "./gruposActions";

const gruposReducer = (state, action) => {
    switch(action.type){

        case ACTUALIZAR_LIST_GROUP : {
            return {
                ...state,
                gruposList: action.payload
            }
        };

        case MODIFICAR_GROUP: {
            console.log(state);
            return {
                ...state,
                gruposList: state.gruposList.map((g) => g._id === action.payload._id ? action.payload : g)
            }
        };


        case AGREGAR_GROUP:{
           return {
                ...state,
                gruposList: [action.payload, ...state.gruposList]
            }
        };

        case ELIMINAR_GROUP:{
           return {
                ...state,
                gruposList: state.gruposList.filter((g) => g._id !== action.payload)
            }
        };

        default:
            return state;
    }
}

export default gruposReducer;